import { API_ENDPOINTS } from "Constants/api.constants";
import React, { useState } from "react";
import { Translate } from "react-localize-redux";
import { ConfirmationModal } from "UI";
import { useAPI } from "Utils";

const ResetComputerModal = ({
  setShowResetComputerConfirmationModal,
  setShowMachineResetSuccessModal,
  getWorkstationsAPI,
  translate,
}) => {
  const [error, setError] = useState(null);

  const { api: resetWorkstationAPI } = useAPI({
    type: "post",
    endpoint: API_ENDPOINTS.WORKSTATION_RESET,
    onErr: () => {
      setError(true);
    },
    onSuccess: () => {
      setShowResetComputerConfirmationModal(false);
      setShowMachineResetSuccessModal(true);
      getWorkstationsAPI();
    },
  });

  return (
    <ConfirmationModal
      headerText={translate("accountSettings.resetComputer.confirm.header")}
      descriptionText={translate("accountSettings.resetComputer.confirm.description")}
      errorText={
        error && (
          <Translate
            id={`accountSettings.resetComputer.error.${error}`}
            options={{ onMissingTranslation: () => translate("accountSettings.resetComputer.error") }}
          />
        )
      }
      confirmText={translate("accountSettings.resetComputer.confirm.confirm")}
      confirmAction={() => {
        resetWorkstationAPI();
      }}
      closeAction={() => setShowResetComputerConfirmationModal(false)}
      secondaryText={translate("accountSettings.resetComputer.confirm.secondary")}
      secondaryAction={() => {
        setShowResetComputerConfirmationModal(false);
      }}
      closeOnOverlayClick
    />
  );
};

export default ResetComputerModal;
