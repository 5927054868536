import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import OrganizationFiles from "Components/Organization/Files/Files.component";

import { getUserFiles, getFileStorageCapacity, clearUserFiles } from "Actions/Dashboard/Files.actions";
import { getOrganizationAPI, getSeatsMeAPI } from "Actions/Organization.actions";

import { REQUEST_STATUS } from "Constants/global.constants";
import { getProductOnboardingAPI } from "Actions/Account.actions";

const GET_FILES_POLLING_INTERVAL = 10000;

const FilesContainer = ({ history, translate }) => {
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state);
  const { currentFolder, userFilesCTXStatus } = files.userFilesCTX;

  useEffect(() => {
    dispatch(getFileStorageCapacity());
    dispatch(getSeatsMeAPI());
    dispatch(getOrganizationAPI());
    dispatch(getProductOnboardingAPI());

    if (files.userFilesCTX.status === REQUEST_STATUS.SUCCESS) {
      dispatch(getUserFiles({ isRefresh: true, filter: "" }));
    } else {
      dispatch(getUserFiles({}));
    }

    return () => {
      dispatch(clearUserFiles());
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userFilesCTXStatus !== REQUEST_STATUS.PENDING) {
        dispatch(getUserFiles({ id: currentFolder.id, isRefresh: true }));
      }
      dispatch(getFileStorageCapacity());
    }, GET_FILES_POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [currentFolder]);

  const loadFileContents = (id, move, refresh = false) => {
    dispatch(getUserFiles({ id, move, isRefresh: refresh }));
  };

  const searchFiles = (id, filter) => {
    dispatch(getUserFiles({ id, move: false, filter, isRefresh: true }));
  };

  return (
    <OrganizationFiles
      searchFiles={searchFiles}
      loadFileContents={loadFileContents}
      history={history}
      translate={translate}
    />
  );
};

export default FilesContainer;
