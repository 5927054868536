import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { getWorkstationSoftwaresAPI } from "Actions/Dashboard/Workstations.actions";
import ChromeLogo from "Assets/icons/chrome.svg";
import WindowsLogo from "Assets/icons/windows.svg";
import VagonSuprise from "Assets/images/preinstall-info.png";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import { ORGANIZATION_TEMPLATES_DUMMY_DATA } from "Constants/teamsOnboarding.constants";
import _ from "lodash";
import { apiGenerator, getItemFromLocalStorage, Loader, saveItemToLocalStorage } from "Utils";
import { BoxInput, Divider, TextButton, IconButton, Icon, Button } from "UI";
import CreateComputerAction from "Components/Dashboard/CreateComputer/Components/CreateComputerAction/CreateComputerAction.component";
import SoftwareCard from "Components/Dashboard/CreateComputer/CreateComputerPreInstall/SoftwareCard/SoftwareCard.component";
import SoftwareRequestCard from "Components/Dashboard/CreateComputer/CreateComputerPreInstall/SoftwareCard/SoftwareRequestCard.component";
import "./NewTemplate.styles.scss";

const BASE_SIZE = 20;
const OS_SIZE = 19;
const CHROME_SIZE = 1;

const TAGS = {
  digitalArt: "Digital Art",
  videoEditing: "Video & Motion",
  architecture: "Architecture & CAD",
  gameDesign: "Game Design",
  essentials: "Essentials",
  gaming: "Gaming",
  dataScience: "Data Science",
};

const NewTemplate = ({ onboardingModalCurrentStep, showTeamsCreateTemplateOnboardingModal, history, translate }) => {
  const [selectedSoftwares, setSelectedSoftwares] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const dispatch = useDispatch();
  const { getSoftwaresCTX } = useSelector((state) => state.workstations);
  const { account } = useSelector((state) => state);

  const { softwares, packages } = getSoftwaresCTX;

  const [showVagonAppInfo, setShowVagonAppInfo] = useState(
    getItemFromLocalStorage(LOCAL_STORAGE.showVagonAppInfo, true),
  );

  const selectedSoftwareIds = _.map(selectedSoftwares, (sw) => sw.id);

  const estimatedTemplateSize = useMemo(() => {
    const estimatedSize =
      _.reduce(
        selectedSoftwares,
        (acc, sw) => {
          const { size } = sw.attributes;
          return acc + size;
        },
        0,
      ) +
      (selectedPackage?.attributes?.size || BASE_SIZE) +
      OS_SIZE +
      CHROME_SIZE;

    return parseFloat(estimatedSize, 10).toFixed(1);
  }, [selectedSoftwares, selectedPackage]);

  const [searchInput, setSearchInput] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);

  const errorTimeoutRef = useRef(null);

  const createSilverImageMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES_INSTALL, data);
    },
    onSuccess: (resp) => {
      history.push({
        pathname: routes.organizationAdminTemplates,
        state: { createdSilverImageId: resp.data.id },
      });
    },
  });

  useEffect(() => {
    dispatch(getWorkstationSoftwaresAPI());

    return () => {
      clearTimeout(errorTimeoutRef.current);
    };
  }, []);

  const onActionButtonClick = () => {
    const body = {
      softwares: selectedSoftwareIds.map((id) => parseInt(id, 10)),
    };

    if (selectedPackage) {
      body.golden_image_type = selectedPackage.attributes.image_type;
    }
    createSilverImageMutation.mutate(body);
  };

  const handleSoftwareClick = (software) => {
    const { id } = software;
    const indexOfId = _.findIndex(selectedSoftwares, (sw) => sw.id === id);

    if (indexOfId > -1) {
      setSelectedSoftwares(selectedSoftwares.filter((sw) => sw.id !== id));
    } else {
      setSelectedSoftwares([...selectedSoftwares, software]);
    }
  };

  const handlePackageClick = (clickedPackage) => {
    if (selectedPackage) {
      setSelectedPackage(null);
    } else {
      setSelectedPackage(clickedPackage);
    }
  };

  const handleShowVagonAppInfoClose = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.showVagonAppInfo, false);
    setShowVagonAppInfo(false);
  };

  const onCloseButtonClick = () => {
    handleShowVagonAppInfoClose();
  };

  if (getSoftwaresCTX.status !== REQUEST_STATUS.SUCCESS) {
    return <Loader />;
  }

  return (
    <div className="teams-new-template-container">
      <div className="softwares-board">
        <div className="search-container mobile-only-hide">
          <div className="search-input">
            <BoxInput
              placeholder={translate("selectSoftware.content.searchPlaceholder")}
              iconName="search"
              color="purple-main"
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
              }}
            />
          </div>
          <div className="search-tags">
            <TextButton
              text="All"
              selected={!selectedTag}
              onClick={() => {
                setSelectedTag(null);
              }}
            />
            {Object.values(TAGS).map((tag) => (
              <TextButton
                key={tag}
                text={tag}
                selected={selectedTag === tag}
                onClick={() => {
                  if (selectedTag === tag) setSelectedTag(null);
                  else setSelectedTag(tag);
                }}
              />
            ))}
          </div>
        </div>

        <h1 className="mobile-only">{translate("selectSoftware.content.softwaresHeader")}</h1>
        <div className="software-list-container" id="software-list-container">
          <div className="software-list">
            {showVagonAppInfo && (
              <div className="vagon-application-info">
                <div className="header">
                  <img src={VagonSuprise} alt="vagon-suprise" />
                </div>
                <div className="description">
                  <IconButton label="close" onClick={onCloseButtonClick} name="close" color="white" />
                  <p>{translate("selectSoftware.content.vagonInfo.description")}</p>
                </div>
              </div>
            )}
            {_.map(packages, (softwarePackage) => {
              if (searchInput && !softwarePackage.attributes.name.toLowerCase().includes(searchInput.toLowerCase()))
                return null;
              if (selectedTag && !softwarePackage.attributes.tags.includes(selectedTag)) return null;

              return (
                <SoftwareCard
                  key={softwarePackage.id}
                  img={softwarePackage.attributes.logo}
                  name={softwarePackage.attributes.name}
                  softwares={softwarePackage.attributes.softwares}
                  selected={selectedPackage?.id === softwarePackage.id}
                  onClick={() => {
                    if (!showTeamsCreateTemplateOnboardingModal) handlePackageClick(softwarePackage);
                  }}
                />
              );
            })}
            {_.map(softwares, (software) => {
              if (searchInput && !software.attributes.name.toLowerCase().includes(searchInput.toLowerCase()))
                return null;
              if (selectedTag && !software.attributes.tags.includes(selectedTag)) return null;

              return (
                <SoftwareCard
                  key={software.id}
                  name={software.attributes.name}
                  img={software.attributes.logo}
                  version={software.attributes.version}
                  size={software}
                  selected={selectedSoftwareIds.includes(software.id)}
                  onClick={() => {
                    if (!showTeamsCreateTemplateOnboardingModal) handleSoftwareClick(software);
                  }}
                />
              );
            })}
            <SoftwareRequestCard translate={translate} />
          </div>
          <div className="small-screen-only">
            <CreateComputerAction
              buttonText={
                account.isTeamAccount
                  ? translate("selectSoftware.action.teamMember")
                  : translate("selectSoftware.action.next")
              }
              onButtonClick={onActionButtonClick}
            />
          </div>
        </div>

        <div className="estimated-size-container">
          <div className="estimated-size">
            <Icon name="info" color="gray-3" /> <p>Estimated Template Size: {estimatedTemplateSize}GB </p>
          </div>
        </div>
      </div>
      <div className="selected-software-list mobile-only-hide">
        <h1>{translate("selectSoftware.content.selectedSoftwaresHeader")}</h1>
        <div className="selected-sotwares">
          <div className="software-row">
            <SoftwareCard img={WindowsLogo} name="OS" size={OS_SIZE} small hideText />
            <SoftwareCard img={ChromeLogo} name="Chrome" size={CHROME_SIZE} small hideText />
            {(showTeamsCreateTemplateOnboardingModal ? ORGANIZATION_TEMPLATES_DUMMY_DATA : selectedSoftwares)?.map(
              (software) => {
                if (showTeamsCreateTemplateOnboardingModal && onboardingModalCurrentStep !== 2) {
                  return null;
                }
                return (
                  <SoftwareCard
                    key={software.id}
                    name={software.attributes.name}
                    size={software.attributes.size}
                    img={software.attributes.logo}
                    small
                    hideText
                  />
                );
              },
            )}
          </div>
          {selectedPackage && (
            <div className="selected-package">
              <SoftwareCard
                key={selectedPackage.id}
                images={_.map(selectedPackage.attributes.softwares, (sw) => sw.attributes.logo)}
                name={selectedPackage.attributes.name}
                size={selectedPackage.attributes.size - BASE_SIZE}
                small
                wide
                hideText
              />
            </div>
          )}
        </div>

        <div className="create-template-action-container">
          {showTeamsCreateTemplateOnboardingModal ? (
            <>
              <Divider color="white-fill" withLogo />
              <div className="onboarding-template-button">
                <Button text="Create Template" big />
              </div>
            </>
          ) : (
            <CreateComputerAction
              buttonText="Create Template"
              onButtonClick={onActionButtonClick}
              actionContent={<Divider color="white-fill" withLogo />}
              disabled={selectedSoftwares.length === 0 && !selectedPackage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewTemplate;
