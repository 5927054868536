import * as FilesConstants from "Constants/Dashboard/Files.constants";
import { UPLOAD_TYPES } from "Constants/global.constants";

export const getUserFiles = ({ id, move, page, perPage, filter, isRefresh }) => ({
  type: move ? FilesConstants.GET_MOVE_FILES_PENDING : FilesConstants.GET_USER_FILES_PENDING,
  payload: { parentId: id, move, page, perPage, query: filter, isRefresh },
});

export const getRecentFiles = (page, perPage, query) => ({
  type: FilesConstants.GET_RECENT_FILES_PENDING,
  payload: { page, perPage, query },
});

export const getFileStorageCapacity = () => ({
  type: FilesConstants.GET_FILE_STORAGE_CAPACITY_PENDING,
});

export const deleteFile = (id, currentFolderId) => ({
  type: FilesConstants.DELETE_FILE_PENDING,
  payload: { id, currentFolderId },
});

export const renameFile = (id, fileName, currentFolderId) => ({
  type: FilesConstants.RENAME_FILE_PENDING,
  payload: { id, fileName, currentFolderId },
});

export const moveFile = (id, selectedFolderId, currentFolderId) => ({
  type: FilesConstants.MOVE_FILE_PENDING,
  payload: { id, currentFolderId, selectedFolderId },
});

export const createFolder = (folderName, currentFolderId, item) => ({
  type: FilesConstants.CREATE_FOLDER_PENDING,
  payload: { folderName, currentFolderId, item },
});

export const downloadFile = (fileId, fileName, fileSize) => ({
  type: FilesConstants.DOWNLOAD_FILE_PENDING,
  payload: { fileId, fileName, fileSize },
});

export const downloadFileAPISuccess = ({ fileName, fileSize, downloadUrl: url }) => ({
  type: FilesConstants.DOWNLOAD_FILE_SUCCESS,
  payload: { fileName, fileSize, url },
});

export const uploadFilesToFolder = ({
  files,
  currentFolderId = null,
  isRecentFiles = false,
  overwrite = false,
  uploadType = UPLOAD_TYPES.FILE_UPLOAD,
  organizationMachineId,
}) => ({
  type: FilesConstants.UPLOAD_FILE_PENDING,
  payload: { files, currentFolderId, isRecentFiles, overwrite, uploadType, organizationMachineId },
});

export const uploadStreamSessionFile = ({ files, uid, uploadType = UPLOAD_TYPES.STREAM_SESSION_FILE_UPLOAD }) => ({
  type: FilesConstants.UPLOAD_FILE_PENDING,
  payload: { files, options: { uid }, uploadType },
});

export const uploadApplicationFile = ({ files, fileContents, os }) => ({
  type: FilesConstants.UPLOAD_FILE_PENDING,
  payload: { files, uploadType: UPLOAD_TYPES.APPLICATION_UPLOAD, fileContents, options: { os } },
});

export const uploadApplicationVersionFile = ({ files, fileContents, appId }) => ({
  type: FilesConstants.UPLOAD_FILE_PENDING,
  payload: { files, uploadType: UPLOAD_TYPES.APPLICATION_VERSION_UPLOAD, fileContents, options: { appId } },
});

export const uploadImage = ({ appId, files, uploadType }) => ({
  type: FilesConstants.UPLOAD_FILE_PENDING,
  payload: { files, options: { appId }, uploadType },
});

export const clearUploadedFile = (fileName) => ({
  type: FilesConstants.UPLOAD_FILE_CLEAR,
  payload: { fileName },
});

export const clearUploadedFiles = () => ({
  type: FilesConstants.UPLOAD_FILES_CLEAR,
});

export const clearWorkstationUploadedFile = (fileName) => ({
  type: FilesConstants.WORKSTATION_UPLOAD_FILE_REMOVE,
  payload: { fileName },
});

export const cancelFileUpload = (fileName) => ({
  type: FilesConstants.UPLOAD_FILE_CANCEL,
  payload: { fileName },
});

export const resetAppUpload = (fileId) => ({
  type: FilesConstants.APPLICATION_UPLOAD_FILE_REMOVE,
  payload: { fileId },
});

export const resetAppVersionUpload = (fileId) => ({
  type: FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_REMOVE,
  payload: { fileId },
});

export const setShowFileProgressBar = (showProgressBar) => ({
  type: FilesConstants.UPLOAD_FILE_PROGRESS_BAR_UPDATE,
  payload: { showProgressBar },
});

export const clearUserFiles = () => ({
  type: FilesConstants.CLEAR_USER_FILES,
});
