import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

import { Modal, CreditCard, RadioInput } from "UI";
import { getPaymentClientSecretAPI, createUserPaymentMethodAPI } from "Actions/Dashboard/Payment.actions";
import { REQUEST_STATUS } from "Constants/global.constants";
import PoweredByStripe from "Assets/images/stripe/powered_by_stripe.png";

import "./AddPaymentMethodModal.styles.scss";
import PaymentMethodForm from "../PaymentMethodForm/PaymentMethodForm";

const AddPaymentMethodModal = (props) => {
  const { payment, setShowAddPaymentMethodModal, onClose, translate } = props;

  const [error, setError] = useState();
  const [pending, setPending] = useState();
  const [cardHolderName, setCardHolderName] = useState();
  const [brand, setBrand] = useState();
  const [isCardInputComplete, setIsCardInputComplete] = useState(false);
  const [isPaymentMethodFormComplete, setIsPaymentMethodFormComplete] = useState(false);

  const [isPaymentPermissionGiven, setIsPaymentPermissionGiven] = useState(false);
  const [showBillingSection, setShowBillingSection] = useState(false);

  const paymentMethodFormRef = useRef(null);
  const errorTimeout = useRef(null);

  useEffect(() => {
    if (payment.createUserPaymentMethodCTX.status !== REQUEST_STATUS.PENDING) {
      setPending(false);
      if (payment.createUserPaymentMethodCTX.status === REQUEST_STATUS.SUCCESS) {
        setShowAddPaymentMethodModal(false);
      } else if (payment.createUserPaymentMethodCTX.error) {
        setError({ clientCode: payment.createUserPaymentMethodCTX.client_code });
      }
    }
  }, [payment.createUserPaymentMethodCTX]);

  const onActionButtonClick = () => {
    if (!showBillingSection) {
      if (cardHolderName && isCardInputComplete) {
        setShowBillingSection(true);
      }
    } else if (isPaymentMethodFormComplete && paymentMethodFormRef.current) {
      if (!pending) {
        setPending(true);
        const event = new Event("submit", { cancelable: true, bubbles: true });
        paymentMethodFormRef.current.dispatchEvent(event);
      }
    }
  };

  useEffect(() => {
    if (error) {
      errorTimeout.current = setTimeout(() => {
        setError(null);
        setShowBillingSection(false);
      }, 3000);
    }

    return () => {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }
    };
  }, [error]);

  const isButtonDisabled = () => {
    if (pending) {
      return true;
    }
    if (!showBillingSection) {
      return !(cardHolderName && isCardInputComplete && isPaymentPermissionGiven);
    }

    if (error) {
      return true;
    }

    return !isPaymentMethodFormComplete;
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setShowAddPaymentMethodModal(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick
      closeAction={handleClose}
      topRightIcon="close"
      topRightIconAction={handleClose}
      headerText={translate("addPaymentForm.header")}
      descriptionText={translate("addPaymentForm.description")}
      buttonText={showBillingSection ? translate("addPaymentForm.complete") : translate("addPaymentForm.next")}
      buttonDisabled={isButtonDisabled()}
      buttonAction={onActionButtonClick}
      errorText={
        error &&
        (error.message ? (
          error.message
        ) : (
          <Translate
            id={`addPaymentForm.error.${error.clientCode}`}
            options={{ onMissingTranslation: () => translate("addPaymentForm.error.default") }}
          />
        ))
      }
      errorable
    >
      <div className="add-new-payment-modal">
        <div className="credit-card-section">
          <CreditCard
            type="big"
            provider={brand}
            cardOwner={cardHolderName || translate("addPaymentForm.cardHoldersName")}
            cardPlaceholder="Credit Card"
            changable={false}
          />
          <div className="stripe-info">
            <img src={PoweredByStripe} alt="Powered by Stripe" className="stripe-image" />
          </div>
        </div>
        <div className="payment-method-form-section">
          <div className="payment-method-form-wrapper">
            <PaymentMethodForm
              formRef={paymentMethodFormRef}
              setPending={setPending}
              setError={setError}
              setIsCardInputComplete={setIsCardInputComplete}
              setIsPaymentMethodFormComplete={setIsPaymentMethodFormComplete}
              setBrand={setBrand}
              setCardHolderName={setCardHolderName}
              createUserPaymentMethodAPI={createUserPaymentMethodAPI}
              getPaymentClientSecretAPI={getPaymentClientSecretAPI}
              showBillingSection={showBillingSection}
              setShowBillingSection={setShowBillingSection}
              divided
              {...props}
            />
          </div>
          {!showBillingSection && (
            <RadioInput
              checked={isPaymentPermissionGiven}
              onChange={() => {
                setIsPaymentPermissionGiven(!isPaymentPermissionGiven);
              }}
              label={translate("addPaymentForm.paymentPermission")}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  payment: state.payment,
});

const actionCreators = {
  getPaymentClientSecretAPI,
  createUserPaymentMethodAPI,
};

export default connect(mapStateToProps, actionCreators)(AddPaymentMethodModal);
