/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { LOCAL_STORAGE, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import { apiGenerator, isMobile, Logger } from "Utils";
import {
  startPixelStreaming,
  connect,
  fullscreen,
  requestPointerLockForPlayer,
  setOnGameModeDisabled,
} from "Utils/PixelStreaming/index";
import routes from "Constants/Route.constants";
import {
  DOCK_POSITIONS,
  DOCK_VISIBILITY,
  ERRORS,
  ERROR_CODE,
  SOUND_AND_MICROPHONE_OPTIONS,
} from "Constants/AppStreaming.constants";
import {
  ToastNotificationContainer,
  showToastNotification,
} from "Components/Workstation/ToastNotification/ToastNotification";
import AppStreamingLoader from "Components/AppStreaming/AppStreamingLoader/AppStreamingLoader.component";
import AppStreamingDock from "Components/AppStreaming/AppStreamingDock/AppStreamingDock.component";
import { useHotkeys } from "react-hotkeys-hook";
import { getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

import "./PixelStreaming.styles.scss";

export function Hotkey({ hotkey, callback }) {
  useHotkeys(hotkey, callback);
  return null;
}

const PixelStreamingContainer = ({ history, translate, match }) => {
  const [connecting, setConnecting] = useState(false);
  const isPlayButtonClickedRef = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isDockOpen, setIsDockOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [quality, setQuality] = useState("standard");
  const [micEnabled, setMicEnabled] = useState(false);
  const [isGameModeDisabledNotificationShown, setIsGameModeDisabledNotificationShown] = useState(false);

  const sessionExpireTimeoutRef = useRef(null);

  const streamUid = match.params?.uri;

  const sendParentMessageAndRedirect = (error) => {
    const message = JSON.stringify({ error });
    window.parent.postMessage(message, "*");

    setTimeout(() => {
      history.push({
        pathname: routes.appStreamingError,
        streamID,
        error: ERRORS.SESSION_IDLE,
      });
    }, 1000);
  };

  const { data: machineData } = useQuery({
    queryKey: [API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)),
    enabled: !!streamUid,
    refetchInterval: 7000,
    keepPreviousData: true,
    onError: (err) => {
      const { client_code: clientCode } = err?.response?.data;

      let error = ERRORS.STREAM_NOT_FOUND;
      if (clientCode === ERROR_CODE.INSTALLATION_FAILED) {
        error = ERRORS.INSTALLATION_FAILED;
      }

      if (clientCode === ERROR_CODE.SESSION_EXPIRED) {
        error = ERRORS.SESSION_EXPIRED;
      }

      sendParentMessageAndRedirect(error);
    },
  });

  const machine = machineData?.data;

  const {
    friendly_status: friendlyStatus,
    application,
    dark_mode: darkMode,
    texts,
    dock_position: dockPosition,
    key_mapping_selection: keyMappingSelection,
    microphone,
    maximum_session_duration: maxDuration,
    active_session_start_at: startAt,
    maximum_session_duration_enabled: maxSessionDurationEnabled,
    active_stream_id: streamID,
  } = machine?.attributes || {};

  const { banner_url: bannerURL, logo_url: logoURL, enterprise, name } = application?.attributes || {};

  useEffect(() => {
    if (maxSessionDurationEnabled && startAt) {
      const estimatedRemainingSeconds = new Date(startAt).getTime() + maxDuration * 60 * 1000 - new Date().getTime();

      const timeout = setTimeout(() => {
        showToastNotification({
          description: translate("sessionExpireMessage"),
          autoClose: 50000,
        });
      }, estimatedRemainingSeconds - 60000);

      if (sessionExpireTimeoutRef.current) {
        clearTimeout(sessionExpireTimeoutRef.current);
      }
      sessionExpireTimeoutRef.current = timeout;
    }
  }, [maxSessionDurationEnabled, maxDuration, startAt]);

  const onIdleTimeEnd = () => {
    const message = JSON.stringify({ error: ERRORS.SESSION_IDLE });
    window.parent.postMessage(message, "*");
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const playButtonIdleTimeEnd = () => {
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const onWarningTimeEnd = () => {
    showToastNotification({
      description: translate("sessionIdleMessage"),
    });
  };

  const playButtonWarningEnd = () => {
    showToastNotification({
      description: translate("pixelStreamingSessionIdleMessage"),
      autoClose: 10000,
    });
  };

  useEffect(() => {
    if (machine && machine?.attributes?.friendly_status === WORKSTATION_FRIENDLY_STATUS.READY && !isConnected) {
      startPixelStreaming({
        streamUid,
        idleDuration: machine.attributes.idle_duration,
        onIdleTimeEnd,
        onWarningTimeEnd,
        setShowLoader,
        keyMappingSelection,
        setConnecting,
        onGameModeDisabled,
        microphone: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start === microphone,
        audioInputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice, null)?.deviceId,
        audioOutputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice, null)?.deviceId,
      });
      setIsConnected(true);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonWarningEnd();
        }
      }, 45000);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonIdleTimeEnd();
        }
      }, 60000);
      Logger.log("pixelStreamingStarted");
    }
  }, [machine]);

  const changeSoundState = (state) => {
    setSoundEnabled(state);
    const player = document.getElementById("streamingAudio");
    if (player) {
      player.muted = !state;
    }
  };

  const toggleFullscreen = () => {
    fullscreen();
  };

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
    // handle mic
  };

  const onGameModeDisabled = () => {
    if (!isGameModeDisabledNotificationShown) {
      setTimeout(() => {
        showToastNotification({
          header: translate("360viewMode.disabled.header"),
          description: translate("360viewMode.disabled.description"),
        });
      }, 10);
      setIsGameModeDisabledNotificationShown(true);
    }
  };

  useEffect(() => {
    setOnGameModeDisabled(onGameModeDisabled);
  }, [isGameModeDisabledNotificationShown]);

  return (
    <div className="pixelstreaming-container">
      <Hotkey
        hotkey="ctrl+g"
        callback={() => {
          requestPointerLockForPlayer();
        }}
      />
      <div id="playerUI" className="noselect">
        <div id="playerPixel">
          {machine && showLoader && (
            <div className="loading-container">
              <AppStreamingLoader
                status={connecting ? "ready" : friendlyStatus}
                texts={texts}
                dark={darkMode}
                enterprise={enterprise}
                bannerURL={bannerURL}
                logoURL={logoURL}
                translate={translate}
                appName={name}
                connected
                showPlayButton={!connecting}
                initialPercentage={85}
                microphone={microphone}
                handlePlayButtonClick={() => {
                  setConnecting(true);
                  connect({
                    microphone: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start === microphone,
                    audioInputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice, null)?.deviceId,
                    audioOutputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice, null)?.deviceId,
                  });
                  isPlayButtonClickedRef.current = true;
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ToastNotificationContainer />
      {isConnected && !isMobile && dockPosition !== DOCK_POSITIONS.hide && (
        <AppStreamingDock
          isDockOpen={isDockOpen}
          displayDockButton={false}
          setIsDockOpen={setIsDockOpen}
          translate={translate}
          soundEnabled={soundEnabled}
          changeableSound={false}
          dockVisibility={DOCK_VISIBILITY.always_visible}
          dockColor=""
          changeSoundState={changeSoundState}
          quality={quality}
          setQuality={setQuality}
          changeableQuality={false}
          toggleFullscreen={toggleFullscreen}
          isMicEnabled={micEnabled}
          toggleMic={toggleMic}
          gameModeEnabled={false}
          changeGameMode={() => {}}
          submitStreamPreference={() => {}}
          connected={isConnected}
          dockPosition={dockPosition}
          clickToStart={showLoader}
          pixelStreaming
        />
      )}
    </div>
  );
};

export default PixelStreamingContainer;
