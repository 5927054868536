import React, { useEffect, useState } from "react";
import ReportImage from "Assets/images/appStreaming/expense-report.png";
import { REQUEST_STATUS } from "Constants/global.constants";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import moment from "moment";
import {
  DashboardSubScreenLayout,
  convertDateFormat,
  PaymentMethods,
  AddBalanceModal,
  classnames,
  localDateFormat,
  isMobile,
  apiGenerator,
} from "Utils";
import { dolarToCents } from "Utils/Helpers/functions.helpers";
import { TextButton, DatePickerInput, IconButton, Dropdown, HoverableTooltip } from "UI";
import BillingAddressInformation from "Components/Organization/Admin/CreateComputers/Payment/BillingAddressInformation.component";
import { useQuery } from "@tanstack/react-query";
import SummaryTable from "./SummaryTable/SummaryTable.component";
import TransactionsTable from "./TransactionsTable.component";
import PaymentsTable from "./PaymentsTable.component";
import useAppStreamingBillingAPI from "./Hooks/useAppStreamingBillingAPI";
import useAppStreamingBillingCsv from "./Hooks/useAppStreamingBillingCsv";
import DepositRuleModal from "./DepositRuleModal/DepositRuleModal.component";

import "./AppStreamingBilling.styles.scss";

const TABS = {
  SUMMARY: 0,
  TRANSACTIONS: 1,
  PAYMENTS: 2,
};

const AppStreamingBilling = ({ account, appStreaming, translate, payment, getProductOnboardingAPI }) => {
  const { getVendorAccountCTX, getApplicationsCTX } = appStreaming;
  const [activeTab, setActiveTab] = useState(0);
  const [showDepositBalanceModal, setShowDepositBalanceModal] = useState(false);
  const [showDepositRuleModal, setShowDepositRuleModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [applicationFilter, setApplicationFilter] = useState(null);
  const [streamNameFilter, setStreamNameFilter] = useState(null);
  const [transactionFilter, setTransactionFilter] = useState(null);
  const firstDayOfMonth = moment().startOf("month").format("DD-MM-YYYY");
  const today = convertDateFormat(new Date().toISOString().substring(0, 10));
  const datePickerPlaceHolder = firstDayOfMonth.concat(" - ", today);

  const paymentMethodPresent = payment.getPaymentMethodsCTX.data?.length > 0;

  const startAt = startDate ? localDateFormat(startDate) : firstDayOfMonth;
  const endAt = endDate ? localDateFormat(endDate) : today;

  const { data: balanceData, refetch: refetchBalance } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_VENDOR_BALANCE],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_BALANCE).then((res) => res.data);
    },
  });

  const balance = parseFloat(balanceData?.attributes?.amount || 0).toFixed(2);

  const { filterOptionsData, chargeDetailProps, vendorPaymentsProps, vendorBalanceStats } = useAppStreamingBillingAPI(
    activeTab,
    TABS,
    useQuery,
    startAt,
    endAt,
    applicationFilter,
    streamNameFilter,
    transactionFilter,
  );

  const { generateCsv, enabled: generateCsvEnabled } = useAppStreamingBillingCsv({
    startAt,
    endAt,
    applicationFilter,
    streamNameFilter,
    transactionFilter,
    vendorBalanceStats,
    vendorPayments: vendorPaymentsProps.data,
    chargeDetails: chargeDetailProps.data,
    translate,
  });

  const applicationFilterOptions = [
    { id: null, name: "All Applications" },
    ...(filterOptionsData?.data?.applications || []),
  ];
  const streamNameFilterOptions = [{ id: null, name: "All Streams" }, ...(filterOptionsData?.data?.streams || [])];
  const transactionFilterOptions = ["allTransactions", ...(filterOptionsData?.data?.transaction_types || [])];

  const { deposit_rule: depositRule } = balanceData?.attributes || {};

  useEffect(() => {
    if (depositRule) {
      setTimeout(() => {
        refetchBalance();
      }, 10000); // magic number or websocket ?
    }
  }, [depositRule]);

  useEffect(() => {
    if (!showDepositBalanceModal) {
      setTimeout(() => {
        refetchBalance();
      }, 3000);
    }
  }, [showDepositBalanceModal]);

  useEffect(() => {
    getProductOnboardingAPI();
  }, []);

  const effectiveUsageSpending = vendorBalanceStats?.data?.effective_usage_spending;
  const usageSpending = effectiveUsageSpending ? parseFloat(effectiveUsageSpending).toFixed(3) : "";

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("appStreamingBilling.header.title")}
      headerDescription={translate("appStreamingBilling.header.description")}
      translate={translate}
      waitFor={
        getVendorAccountCTX.status !== REQUEST_STATUS.SUCCESS || getApplicationsCTX.status !== REQUEST_STATUS.SUCCESS
      }
      appStreaming
    >
      <div className="full-page-container white app-streaming-billing-container">
        <div className="row grid-2-columns">
          <div className="single-item">
            <h3>{translate("appStreamingBilling.balance.header")}</h3>
            <div className="item-box">
              <div className="item-header">
                <div>
                  <span>$</span> {balance}
                </div>
                <div className="deposit-actions">
                  <TextButton
                    text={translate("appStreamingBilling.balance.autoAction")}
                    color="purple"
                    onClick={() => setShowDepositRuleModal(true)}
                    disabled={!paymentMethodPresent}
                  />
                  <div className="vertical-divider" />
                  <TextButton
                    text={translate("appStreamingBilling.balance.action")}
                    color="purple"
                    onClick={() => setShowDepositBalanceModal(true)}
                  />
                </div>
              </div>
              <p>{translate("appStreamingBilling.balance.description")}</p>
              <BillingAddressInformation
                billingInformation={getVendorAccountCTX.data?.attributes?.billing_information}
                translate={translate}
                vendor
              />
            </div>
          </div>
          <div className="single-item">
            <h3>{translate("appStreamingBilling.paymentMethods.header")}</h3>
            <PaymentMethods translate={translate} />
          </div>
        </div>
        <div className={classnames(["row header-line", isMobile && "mobile"])}>
          <div className={classnames(["single-item", isMobile && "mobile"])}>
            <div className="tab-buttons-container">
              <TextButton
                text={translate("appStreamingBilling.summary.header")}
                color={activeTab === TABS.SUMMARY ? "purple" : "gray-3"}
                onClick={() => setActiveTab(TABS.SUMMARY)}
              />
              <TextButton
                text={translate("appStreamingBilling.allTransactions.header")}
                color={activeTab === TABS.TRANSACTIONS ? "purple" : "gray-3"}
                onClick={() => setActiveTab(TABS.TRANSACTIONS)}
              />
              <TextButton
                text={translate("appStreamingBilling.payments.header")}
                color={activeTab === TABS.PAYMENTS ? "purple" : "gray-3"}
                onClick={() => setActiveTab(TABS.PAYMENTS)}
              />
            </div>
            <div className="date-picker">
              {activeTab === TABS.TRANSACTIONS && (
                <HoverableTooltip content="Filter">
                  <IconButton
                    className="icons"
                    name="filter"
                    color={showFilter ? "baby-powder" : "gray-4"}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                </HoverableTooltip>
              )}
              <HoverableTooltip content="Download">
                <IconButton
                  className="icons"
                  name="file-download"
                  small
                  color="gray-4"
                  disabled={
                    chargeDetailProps.isLoading ||
                    vendorPaymentsProps.isLoading ||
                    !vendorBalanceStats ||
                    !generateCsvEnabled
                  }
                  onClick={() => {
                    generateCsv();
                  }}
                />
              </HoverableTooltip>
              <DatePickerInput
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                placeHolder={datePickerPlaceHolder}
              />
            </div>
          </div>
          {showFilter && activeTab === TABS.TRANSACTIONS && (
            <div className="filter-row">
              <Dropdown
                underlined
                options={applicationFilterOptions}
                mapFunction={(option) => {
                  return option.name;
                }}
                handleSelectedOptionChange={(e) => {
                  setApplicationFilter(e);
                  if (applicationFilter?.id !== e?.id && e.name !== "All Applications") {
                    setStreamNameFilter(null);
                    setTransactionFilter(null);
                  }
                }}
                defaultSelected={applicationFilter}
                placeholder="Application"
                selectedOption={applicationFilter}
                disabled={applicationFilterOptions.length === 1}
              />
              <Dropdown
                underlined
                options={streamNameFilterOptions}
                mapFunction={(option) => option.name}
                handleSelectedOptionChange={(e) => {
                  setStreamNameFilter(e);
                }}
                defaultSelected={streamNameFilter}
                placeholder="Stream"
                disabled={streamNameFilterOptions.length === 1}
              />
              <Dropdown
                underlined
                options={transactionFilterOptions}
                mapFunction={(option) => translate(`appStreamingBilling.chargeDetails.transactionTypes.${option}`)}
                handleSelectedOptionChange={(e) => {
                  setTransactionFilter(e);
                }}
                defaultSelected={transactionFilter}
                placeholder="Transaction"
                disabled={transactionFilterOptions.length === 1}
              />
            </div>
          )}
        </div>
        <div className={classnames(["row", activeTab === 0 && "grid-2-columns"])}>
          <div className="single-item details">
            <div className="item-box fixed-height" style={{ padding: "0.5rem" }}>
              {activeTab === TABS.SUMMARY && <SummaryTable data={vendorBalanceStats} translate={translate} />}
              {activeTab === TABS.TRANSACTIONS && (
                <TransactionsTable
                  chargeDetailProps={chargeDetailProps}
                  translate={translate}
                  className="app-streaming-transaction-table"
                />
              )}
              {activeTab === TABS.PAYMENTS && (
                <PaymentsTable vendorPaymentsProps={vendorPaymentsProps} translate={translate} />
              )}
            </div>
          </div>
          {activeTab === TABS.SUMMARY && !isMobile && (
            <div className="single-item">
              <div className="item-box children-centered report-box">
                <img src={ReportImage} />
                <span>
                  {dolarToCents(usageSpending)}¢<span>{translate("appStreamingBilling.expenseReport.timeUnit")}</span>
                </span>
                <p>{translate("appStreamingBilling.expenseReport.effectiveSpent")}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDepositBalanceModal && (
        <AddBalanceModal setShowAddBalanceModal={setShowDepositBalanceModal} translate={translate} />
      )}
      {showDepositRuleModal && (
        <DepositRuleModal
          depositRule={depositRule}
          refetchBalance={refetchBalance}
          onClose={() => setShowDepositRuleModal(false)}
          translate={translate}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default AppStreamingBilling;
