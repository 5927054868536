import React from "react";
import "./HistoryCard.styles.scss";
import { classnames } from "Utils";

const HistoryCard = (props) => {
  const { headers, keys, item, setCard, card } = props;
  return (
    <div
      className={classnames(["vg-history-card", card === item?.id && "active"])}
      onClick={() => {
        setCard(item?.id);
      }}
    >
      {keys.map((key, idx) => (
        <div className="vg-history-card-item" key={headers[idx]}>
          <div className="vg-history-card-title">{headers[idx]}</div>
          <div className="vg-history-card-description">{item[key]}</div>
        </div>
      ))}
    </div>
  );
};

export default HistoryCard;
