import { CPU_STREAMING_SETTINGS, GPU_STREAMING_SETTINGS } from "Constants/streaming.constants";
import { saveItemToLocalStorage, getItemFromLocalStorage } from "Utils";
import { isMacOS } from "./browser.helpers";

const { LOCAL_STORAGE } = require("Constants/global.constants");

const WORKSTATION_TYPES = {
  CPU: "CPU",
  GPU: "GPU",
};

export const getStreamPreferences = (workstationType) => {
  const localPreferences = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {});
  if (!localPreferences.quality) {
    localPreferences.quality = "Standard";
  }
  if (!localPreferences.fps) {
    localPreferences.fps = 30;
  }
  if (workstationType === WORKSTATION_TYPES.CPU) {
    localPreferences.fps = 30;
  }

  saveItemToLocalStorage(LOCAL_STORAGE.advancedStreamSettings, localPreferences);
  return localPreferences;
};

export const applyAdvancedStreamingSettings = (workstationType, options = {}) => {
  const localStreamSettings = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {});

  let advancedStreamingSettings = { ...GPU_STREAMING_SETTINGS, ...localStreamSettings, ...options };

  if (workstationType === WORKSTATION_TYPES.CPU) {
    advancedStreamingSettings = CPU_STREAMING_SETTINGS;
    advancedStreamingSettings.f = 30;
  }

  saveItemToLocalStorage(LOCAL_STORAGE.advancedStreamSettings, advancedStreamingSettings);
};

export const getSwitchCmdPreference = () => {
  const switchCmdPreference = getItemFromLocalStorage(LOCAL_STORAGE.switchCmdPreference, isMacOS || false);
  return switchCmdPreference;
};

export const getVerticalScrollDirection = () => {
  const localScrollPreference = getItemFromLocalStorage(LOCAL_STORAGE.scrollPreference, {});
  return !!localScrollPreference.inverseVerticalScroll;
};

export const applyScrollDirectionPreference = (inverseDirection) => {
  const localObj = {
    inverseVerticalScroll: inverseDirection,
  };
  saveItemToLocalStorage(LOCAL_STORAGE.scrollPreference, localObj);
};

export const getLastSuccessfulConnectionType = () => {
  return getItemFromLocalStorage(LOCAL_STORAGE.lastSuccessfulConnectionType, "");
};
