export const APP_STREAMING_REGISTER_API_PENDING = "APP_STREAMING_REGISTER_API_PENDING";
export const APP_STREAMING_REGISTER_API_SUCCESS = "APP_STREAMING_REGISTER_API_SUCCESS";
export const APP_STREAMING_REGISTER_API_FAILURE = "APP_STREAMING_REGISTER_API_FAILURE";

export const GET_APPLICATIONS_API_PENDING = "GET_APPLICATIONS_API_PENDING";
export const GET_APPLICATIONS_API_SUCCESS = "GET_APPLICATIONS_API_SUCCESS";
export const GET_APPLICATIONS_API_FAILURE = "GET_APPLICATIONS_API_FAILURE";

export const GET_APPLICATIONS_STATS_API_PENDING = "GET_APPLICATIONS_STATS_API_PENDING";
export const GET_APPLICATIONS_STATS_API_SUCCESS = "GET_APPLICATIONS_STATS_API_SUCCESS";
export const GET_APPLICATIONS_STATS_API_FAILURE = "GET_APPLICATIONS_STATS_API_FAILURE";

export const GET_APPLICATION_API_PENDING = "GET_APPLICATION_API_PENDING";
export const GET_APPLICATION_API_SUCCESS = "GET_APPLICATION_API_SUCCESS";
export const GET_APPLICATION_API_FAILURE = "GET_APPLICATION_API_FAILURE";

export const GET_APPLICATION_STATUS_API_PENDING = "GET_APPLICATION_STATUS_API_PENDING";
export const GET_APPLICATION_STATUS_API_SUCCESS = "GET_APPLICATION_STATUS_API_SUCCESS";
export const GET_APPLICATION_STATUS_API_FAILURE = "GET_APPLICATION_STATUS_API_FAILURE";

export const GET_VENDOR_APPLICATIONS_STATS_API_PENDING = "GET_VENDOR_APPLICATIONS_STATS_API_PENDING";
export const GET_VENDOR_APPLICATIONS_STATS_API_SUCCESS = "GET_VENDOR_APPLICATIONS_STATS_API_SUCCESS";
export const GET_VENDOR_APPLICATIONS_STATS_API_FAILURE = "GET_VENDOR_APPLICATIONS_STATS_API_FAILURE";

export const GET_APPLICATION_IMAGES_API_PENDING = "GET_APPLICATION_IMAGES_API_PENDING";
export const GET_APPLICATION_IMAGES_API_SUCCESS = "GET_APPLICATION_IMAGES_API_SUCCESS";
export const GET_APPLICATION_IMAGES_API_FAILURE = "GET_APPLICATION_IMAGES_API_FAILURE";

export const GET_APPLICATION_MACHINE_TYPES_API_PENDING = "GET_APPLICATION_MACHINE_TYPES_API_PENDING";
export const GET_APPLICATION_MACHINE_TYPES_API_SUCCESS = "GET_APPLICATION_MACHINE_TYPES_API_SUCCESS";
export const GET_APPLICATION_MACHINE_TYPES_API_FAILURE = "GET_APPLICATION_MACHINE_TYPES_API_FAILURE";

export const GET_CUSTOMER_SESSION_STATS_PENDING = "GET_CUSTOMER_SESSION_STATS_PENDING";
export const GET_CUSTOMER_SESSION_STATS_SUCCESS = "GET_CUSTOMER_SESSION_STATS_SUCCESS";
export const GET_CUSTOMER_SESSION_STATS_FAILURE = "GET_CUSTOMER_SESSION_STATS_FAILURE";

export const TEST_APPLICATION_PENDING = "TEST_APPLICATION_PENDING";
export const TEST_APPLICATION_SUCCESS = "TEST_APPLICATION_SUCCESS";
export const TEST_APPLICATION_FAILURE = "TEST_APPLICATION_FAILURE";

export const GET_VENDOR_ACCOUNT_API_PENDING = "GET_VENDOR_ACCOUNT_API_PENDING";
export const GET_VENDOR_ACCOUNT_API_SUCCESS = "GET_VENDOR_ACCOUNT_API_SUCCESS";
export const GET_VENDOR_ACCOUNT_API_FAILURE = "GET_VENDOR_ACCOUNT_API_FAILURE";

export const GET_VENDOR_CHARGE_DETAILS_PENDING = "GET_VENDOR_CHARGE_DETAILS_API_PENDING";
export const GET_VENDOR_CHARGE_DETAILS_SUCCESS = "GET_VENDOR_CHARGE_DETAILS_API_SUCCESS";
export const GET_VENDOR_CHARGE_DETAILS_FAILURE = "GET_VENDOR_CHARGE_DETAILS_API_FAILURE";

export const GET_VENDOR_BALANCE_STATS_PENDING = "GET_VENDOR_BALANCE_STATS_PENDING";
export const GET_VENDOR_BALANCE_STATS_SUCCESS = "GET_VENDOR_BALANCE_STATS_SUCCESS";
export const GET_VENDOR_BALANCE_STATS_FAILURE = "GET_VENDOR_BALANCE_STATS_FAILURE";

export const GET_VENDOR_PAYMENTS_PENDING = "GET_VENDOR_PAYMENTS_PENDING";
export const GET_VENDOR_PAYMENTS_SUCCESS = "GET_VENDOR_PAYMENTS_SUCCESS";
export const GET_VENDOR_PAYMENTS_FAILURE = "GET_VENDOR_PAYMENTS_FAILURE";

export const GET_CUSTOMER_STATS_API_PENDING = "GET_CUSTOMER_STATS_API_PENDING";
export const GET_CUSTOMER_STATS_API_SUCCESS = "GET_CUSTOMER_STATS_API_SUCCESS";
export const GET_CUSTOMER_STATS_API_FAILURE = "GET_CUSTOMER_STATS_API_FAILURE";

export const GET_MACHINE_STATS_API_PENDING = "GET_MACHINE_STATS_API_PENDING";
export const GET_MACHINE_STATS_API_SUCCESS = "GET_MACHINE_STATS_API_SUCCESS";
export const GET_MACHINE_STATS_API_FAILURE = "GET_MACHINE_STATS_API_FAILURE";

export const GET_CURRENT_PLAN_API_PENDING = "GET_CURRENT_PLAN_API_PENDING";
export const GET_CURRENT_PLAN_API_SUCCESS = "GET_CURRENT_PLAN_API_SUCCESS";
export const GET_CURRENT_PLAN_API_FAILURE = "GET_CURRENT_PLAN_API_FAILURE";

export const CREATE_STREAM_API_PENDING = "CREATE_STREAM_API_PENDING";
export const CREATE_STREAM_API_SUCCESS = "CREATE_STREAM_API_SUCCESS";
export const CREATE_STREAM_API_FAILURE = "CREATE_STREAM_API_FAILURE";

export const GET_STREAMS_API_PENDING = "GET_STREAMS_API_PENDING";
export const GET_STREAMS_API_SUCCESS = "GET_STREAMS_API_SUCCESS";
export const GET_STREAMS_API_FAILURE = "GET_STREAMS_API_FAILURE";

export const PAUSE_STREAM_API_PENDING = "PAUSE_STREAM_API_PENDING";
export const PAUSE_STREAM_API_SUCCESS = "PAUSE_STREAM_API_SUCCESS";
export const PAUSE_STREAM_API_FAILURE = "PAUSE_STREAM_API_FAILURE";

export const UPDATE_STREAM_API_PENDING = "UPDATE_STREAM_API_PENDING";
export const UPDATE_STREAM_API_SUCCESS = "UPDATE_STREAM_API_SUCCESS";
export const UPDATE_STREAM_API_FAILURE = "UPDATE_STREAM_API_FAILURE";

export const GET_STREAM_CAPACITIES_API_PENDING = "GET_STREAM_CAPACITIES_API_PENDING";
export const GET_STREAM_CAPACITIES_API_SUCCESS = "GET_STREAM_CAPACITIES_API_SUCCESS";
export const GET_STREAM_CAPACITIES_API_FAILURE = "GET_STREAM_CAPACITIES_API_FAILURE";

export const GET_APP_CAPACITIES_API_PENDING = "GET_APP_CAPACITIES_API_PENDING";
export const GET_APP_CAPACITIES_API_SUCCESS = "GET_APP_CAPACITIES_API_SUCCESS";
export const GET_APP_CAPACITIES_API_FAILURE = "GET_APP_CAPACITIES_API_FAILURE";

export const GET_STREAM_API_PENDING = "GET_STREAM_API_PENDING";
export const GET_STREAM_API_SUCCESS = "GET_STREAM_API_SUCCESS";
export const GET_STREAM_API_FAILURE = "GET_STREAM_API_FAILURE";

export const CAPACITY_WARNING_CONSENT_PENDING = "CAPACITY_WARNING_CONSENT_PENDING";
export const CAPACITY_WARNING_CONSENT_SUCCESS = "CAPACITY_WARNING_CONSENT_SUCCESS";

export const EVENT_TYPE = {
  PRODUCT_STREAMING: "product",
  ONE_TIME_EVENT: "one-time-event",
};

export const OPERATING_SYSTEMS = {
  WINDOWS: "windows",
  LINUX: "linux",
};

export const APPLICATION_STATUS = {
  INITIAL_STATUS: "disabled", // -1
  UPLOAD: "pending", // 0
  PLAN: "planning", // 1
  REVIEW: "reviewing", // 2
  TESTING: "testing", // conf 3
  PAYMENT: "pending_payment", // 4
  ACTIVE: "active", // 5,
  PENDING_CHANGES: "pending_changes", // 6
  PAUSED: "paused", // 6
  PACKAGING: "packaging_apps", // 7
};

export const BG_STYLE_BY_APP_STATUS = {
  disabled: { background: "rgba(245, 82, 42, 0.8)" },
  pending: { backgroundColor: "#FFD600" },
  paused: { backgroundColor: "#FFD600" },
  planing: { backgroundColor: "#ABB3FF" },
  reviewing: { backgroundColor: "#FFD600" },
  testing: { backgroundColor: "#5E73FF" },
  pending_payment: { background: "rgba(245, 82, 42, 0.8)" },
  active: { backgroundColor: "#6FCF97" },
  pending_changes: { backgroundColor: "#FFD600" },
  no_streams: { backgroundColor: "rgb(130, 130, 130)" },
};

export const ICON_COLOR_BY_STREAM_STATUS = {
  pending: "yellow",
  paused: "yellow",
  active: "green-3",
  pending_changes: "yellow",
};

export const BG_STYLE_BY_APP_FRIENDLY_STATUS = {
  payment_waiting: { background: "rgba(245, 82, 42, 0.8)" },
  creating: { backgroundColor: "#6FCF97" },
  paused: { backgroundColor: "#FFD600" },
  no_streams: { background: "rgb(130, 130, 130)" },
  live: { backgroundColor: "#6FCF97" },
  limit_reached: { backgroundColor: "#FFD600" },
  packaging_apps: { animation: "packagingAppsColorChange 2s infinite" },
};

export const BIT_RATES = {
  standard: 4000000,
  moderate: 8000000,
  high: 12000000,
};

export const ERROR_CODE = {
  INSTALLATION_FAILED: 4612,
  SESSION_EXPIRED: 4624,
};

export const ERRORS = {
  INSTALLATION_FAILED: "installation_failed",
  STREAM_NOT_FOUND: "stream_not_found",
  SESSION_EXPIRED: "session_expired",
  DUPLICATE_TAB: "duplicate_tab",
  SESSION_IDLE: "session_idle",
  PIXEL_STREAMING_SESSION_IDLE: "pixel_streaming_session_idle",
  CONNECTION_FAILED: "connection_failed",
};

export const ERROR_TEXT_MAPPING = {
  session_expired: "session_expired_text",
  session_idle: "idle_text",
  connection_failed: "connection_failed_text",
  installation_failed: "installation_failed_text",
};

export const VENDOR_TYPES = {
  basic: "basic",
  pro: "pro",
  enterprise: "enterprise",
};

export const DOCK_VISIBILITY = {
  always_visible: "always_visible",
  show_on_hover: "show_on_hover",
  hide: "hide",
};

export const STREAM_TYPES = {
  regular: "regular",
  parent: "parent",
  child: "child",
};

export const SOUND_AND_MICROPHONE_OPTIONS = {
  off: "off",
  activate_on_start: "activate_on_start",
  user_can_activate: "user_can_activate",
};

export const PS_SOUND_AND_MICROPHONE_OPTIONS = {
  off: "off",
  activate_on_start: "activate_on_start",
};

export const GAME_ENGINES = {
  unity: "unity",
  unreal: "unreal",
};

export const KEYBOARD_LAYOUTS = {
  us: "en-US",
  uk: "en-GB",
  nl: "nl-NL",
  fr: "fr-FR",
  de: "de-DE",
  es: "es-ES",
  tr: "tr-TR",
  no: "no-NO",
  it: "it-IT",
  ja: "ja-JP",
};

export const JOYSTICK_OPTIONS = {
  close: "close",
  mobile_and_tablet: "mobile_and_tablet",
  all_devices: "all_devices",
};

export const STREAM_SCHEDULE_TYPES = {
  always_on: "always_on",
  time_range: "time_range",
  work_days: "work_days",
};

export const STREAM_ERROR_CODES = {
  balance_error: 480,
};

export const MAX_CAPACITY_BY_VENDOR_TYPE = {
  basic: 25,
  pro: 50,
  enterprise: 100,
  default: 10,
};

export const MAX_VENDOR_ADMIN_BY_VENDOR_TYPE = {
  basic: 1,
  pro: 2,
  enterprise: 5,
};

export const VENDOR_ADMIN_TYPES = {
  owner: "owner",
  user: "user",
};

export const streamTextsOrder = [
  "initializing_text",
  "installing_application_text",
  "connecting_text",
  "queue_text",
  "not_active_text",
  "installation_failed_text",
  "reconnect_text",
  "session_expired_text",
  "invalid_email_text",
  "invalid_password_text",
  "idle_text",
  "connection_failed_text",
];

export const DOCK_POSITIONS = {
  top: "top",
  bottom: "bottom",
  left: "left",
  hide: "hide",
};

export const VENDOR_SUBSCRIPTION_STATUS = {
  pending_payment: "pending_payment",
  active: "active",
  payment_failed: "payment_failed",
};

export const REGENERATE_APP_SESSION_ID_PARAM = "newSession";
