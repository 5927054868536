import React from "react";

import { Icon, CreditCard } from "UI";
import { connect } from "react-redux";
import {
  getPaymentClientSecretAPI,
  setPreferredPaymentMethodAPI,
  createUserPaymentMethodAPI,
  deleteUserPaymentMethodAPI,
  resetDeleteUserPaymentMethodAPI,
} from "Actions/Dashboard/Payment.actions";
import { AddPaymentMethodModal, Loader } from "Utils";
import { REQUEST_STATUS } from "Constants/global.constants";

import "./PaymentMethods.styles.scss";

const PaymentMethods = (props) => {
  const {
    account,
    payment,
    setPreferredPaymentMethodAPI,
    deleteUserPaymentMethodAPI,
    paymentType,
    setPaymentType,
    hideAddPaymentMethod,
    disabled,
    translate,
  } = props;

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = React.useState(false);

  switch (payment.getPaymentMethodsCTX.status) {
    case REQUEST_STATUS.SUCCESS: {
      const paymentMethods = props.payment.getPaymentMethodsCTX.data?.sort((a, b) => {
        return a.id - b.id;
      });

      return (
        <>
          <div className="payment-methods">
            {paymentMethods.map((paymentMethod) => (
              <CreditCard
                key={paymentMethod.id}
                deletable // TODO: Add error case when card can not be deleted
                preferable={!paymentMethod.attributes.preferred}
                setPreferredAction={() => {
                  setPreferredPaymentMethodAPI(paymentMethod.id, account.accessToken);
                }}
                deleteAction={(event) => {
                  event.stopPropagation();
                  deleteUserPaymentMethodAPI(account.accessToken, paymentMethod.id);
                }}
                preferred={paymentMethod.attributes.preferred}
                cardNumber={paymentMethod.attributes.last_four_digit}
                cardPlaceholder="Credit Card"
                provider={paymentMethod.attributes.brand}
                className="credit-card"
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />
            ))}
            {paymentMethods.length < 2 && !hideAddPaymentMethod && (
              <div
                role="button"
                className="add-new-payment-method"
                onClick={() => {
                  if (!disabled) setShowAddPaymentMethodModal(true);
                }}
                onKeyDown={() => {
                  if (!disabled) setShowAddPaymentMethodModal(true);
                }}
                tabIndex="-1"
              >
                <Icon name="plus" />
              </div>
            )}
          </div>
          {showAddPaymentMethodModal && (
            <AddPaymentMethodModal setShowAddPaymentMethodModal={setShowAddPaymentMethodModal} translate={translate} />
          )}
        </>
      );
    }
    default: {
      return <Loader big centered />;
    }
  }
};

const mapStateToProps = (state) => ({
  account: state.account,
  payment: state.payment,
});

const actionCreators = {
  getPaymentClientSecretAPI,
  setPreferredPaymentMethodAPI,
  deleteUserPaymentMethodAPI,
  resetDeleteUserPaymentMethodAPI,
  createUserPaymentMethodAPI,
};

export default connect(mapStateToProps, actionCreators)(PaymentMethods);
