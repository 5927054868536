export const ORGANIZATION_ADMIN_ROUTES = {
  organization: "/team/admin/join",
  organizationAdminHome: "/team/admin",
  organizationAdminCreatePlan: "/team/admin/plan/create",
  organizationAdminCreateComputers: "/team/admin/computer/create/:page",
  organizationAdminCreateComputersQuickStart: "/team/admin/computer/create/quick-start",
  organizationAdminCreateComputersCreatePlan: "/team/admin/computer/create/custom-plan",
  organizationAdminCreateComputersCreatePayment: "/team/admin/computer/create/payment",
  organizationAdminPlans: "/team/admin/plans",
  organizationAdminTemplates: "/team/admin/templates",
  organizationAdminBilling: "/team/admin/billing",
  organizationAdminCreateTemplate: "/team/admin/template/create/:page",
  organizationAdminCreateTemplateStart: "/team/admin/template/create/start",
  organizationAdminCreateTemplateNew: "/team/admin/template/create/new",
  organizationAdminCreateTemplateFromComputer: "/team/admin/template/create/from-computer",
  organizationAdminMemberComputer: "/team/admin/connect/member",
  organizationAdminFiles: "/team/admin/files",
  organizationAdminStats: "/team/admin/stats",
  organizationAdminSettings: "/team/admin/settings",
  organizationAdminPermissions: "/team/admin/permissions",
  organizationAdminUsageAnalytics: "/team/admin/usage-analytics",
};

export const ORGANIZATION_MEMBER_ROUTES = {
  organizationMemberHome: "/team/member",
  organizationMemberTeamInfo: "/team/member/team-info",
  organizationMemberComputer: "/team/member/computer",
  organizationMemberFiles: "/team/member/files",
  organizationMemberSessions: "/team/member/sessions",
  organizationMemberBilling: "/team/member/billing",
  organizationMemberSettings: "/team/member/settings",
};

export const STREAMING_ROUTES = {
  workstation404: "/stream-not-found",
  appStreamingLanding: "/stream/landing",
  appStreamingSessionExpired: "/session-expired",
  appStreaming: "/stream/:uri",
  appStreamingHome: "/stream/home",
  appStreamingInfra: "/stream/infrastructure",
  appStreamingBilling: "/stream/billing",
  appStreamingPricing: "/stream/pricing",
  appStreamingAppUpload: "/stream/app-upload",
  appStreamingUsers: "/stream/stats",
  appStreamingSettings: "/stream/settings",
  appStreamingTest: "/stream/test",
  appStreamingConfigureStream: "/stream/streams/configure",
  appStreamingError: "/stream-ended",
};

const routes = {
  ...STREAMING_ROUTES,
  ...ORGANIZATION_ADMIN_ROUTES,
  ...ORGANIZATION_MEMBER_ROUTES,
  home: "/",
  suite: "/suite",
  files: "/files",
  homePages: "/dashboard/:page",
  addStorage: "/dashboard/computer-storage",
  performance: "/dashboard/computer-performance",
  migrateRegion: "/dashboard/migrate-region",
  newComputerConfigs: "/create-new-workstation/workstation-configs/",
  newComputerPayment: "/create-new-workstation/payment",
  createComputer: "/create-computer/:page",
  createComputerPurpose: "/create-computer/purpose-of-use",
  createComputerPreInstall: "/create-computer/select-software",
  createComputerConnection: "/create-computer/connection-status",
  createComputerSubscription: "/create-computer/subscription",
  createComputerPayment: "/create-computer/payment",
  account: "/account",
  referral: "/referral",
  billing: "/billing",
  usage: "/usage",
  login: "/login",
  register: "/register",
  appStreamingRegister: "/stream/register",
  appStreamingLogin: "/stream/login",
  verifyRegister: "/register/confirm/:code",
  verifyStreamRegister: "/stream/register/confirm/:code",
  forgetPassword: "/reset-password",
  forgetPasswordReset: "/reset-password/:code",
  workstation: "/workstation",
  notSupported: "/not-supported",
  workstationDuplicate: "/expired",
  workstation404: "/stream-not-found",
  workstationConnectionFailed: "/connection-failed",
  streamConnectionFailed: "/stream-connection-failed",
  appStreaming: "/stream/:uri",
  appStreamingPixel: "/stream/:uri/pixelstreaming",
  appStreamingLoginFromPlugin: "/stream/login-from-plugin",
  appStreamingSendTokenToPlugin: "/stream/send-token-to-plugin",
  appStreamingHome: "/stream/home",
  appStreamingStreams: "/stream/streams",
  appStreamingUsers: "/stream/stats",
  appStreamingBilling: "/stream/billing",
  appStreamingInstallationFailed: "/stream-installation-failed",
  maintenance: "/maintenance",
  appStreamingSessionIdle: "/stream-session-idle",
};

export default routes;
