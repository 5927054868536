import React, { useState } from "react";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { saveItemToLocalStorage } from "Utils";
import { TextButton, Dropdown } from "UI";
import _ from "lodash";
import { getStreamPreferences } from "Utils/Helpers/streaming.helpers";

const FPS_OPTIONS = [30, 60];
const CPU_CUSTOM_RESOLUTIONS = ["Use Local Resolution", "1440x900", "1920x1080"];

const GPU_CUSTOM_RESOLUTIONS = [
  "Use Local Resolution",
  "1366x768",
  "1440x900",
  "1536x864",
  "1920x1080",
  "2048x1080 | 2K",
  "2560x1080",
  "2560x1440",
  "3840x2160 | 4K",
  "4096x2160 | 4K",
];
const CUSTOM_RESOLUTION_SIZES = {
  "Use Local Resolution": { localResolution: true },
  "1366x768": { x: 1366, y: 768 },
  "1440x900": { x: 1440, y: 900 },
  "1536x864": { x: 1536, y: 864 },
  "1920x1080": { x: 1920, y: 1080 },
  "2048x1080 | 2K": { x: 2048, y: 1080 },
  "2560x1080": { x: 2560, y: 1080 },
  "2560x1440": { x: 2560, y: 1440 },
  "3840x2160 | 4K": { x: 3840, y: 2160 },
  "4096x2160 | 4K": { x: 4096, y: 2160 },
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const STREAM_QUALITY_OPTIONS = {
  Low: "Low",
  Standard: "Standard",
  High: "High",
};

/**
 * OpenH264 = 1
 * x264 = 2
 * NvEnc = 3
 */

const getSelectedOptionFromResolution = (resolution) => {
  if (!resolution) return "1920x1080";
  return _.findKey(CUSTOM_RESOLUTION_SIZES, resolution) || "1920x1080";
};

const DisplayTab = ({
  displayResolution,
  setDisplayResolution,
  workstationType,
  submitStreamPreference,
  translate,
}) => {
  const [customResolutionSelected, setCustomResolutionSelected] = useState(false);
  const [selectedCustomResolution, setSelectedCustomResolution] = useState(
    getSelectedOptionFromResolution(displayResolution),
  );
  const [streamPreferences, setStreamPreferences] = useState(getStreamPreferences(workstationType));
  const [isStreamPreferencesChanged, setIsStreamPreferencesChanged] = useState(false);

  const qualityOptions = _.cloneDeep(STREAM_QUALITY_OPTIONS);
  // 60 fps looks really bad on low quality
  if (streamPreferences.fps === 60) delete qualityOptions.Low;

  return (
    <>
      <h1>{translate("dock.display.sections.resolution")}</h1>
      <TextButton
        className="menu-item"
        text={translate("dock.display.setResolution")}
        onClick={() => {
          setDisplayResolution(CUSTOM_RESOLUTION_SIZES["Use Local Resolution"]);
          setSelectedCustomResolution(CPU_CUSTOM_RESOLUTIONS[0]);
        }}
        disabled={selectedCustomResolution === "Use Local Resolution"}
      />
      <p className="menu-item">{translate("dock.display.advanced.resolution")}</p>
      <div className="menu-item">
        <Dropdown
          defaultSelected={selectedCustomResolution}
          options={workstationType === "GPU" ? GPU_CUSTOM_RESOLUTIONS : CPU_CUSTOM_RESOLUTIONS}
          handleSelectedOptionChange={(option) => {
            setSelectedCustomResolution(option);
            if (!customResolutionSelected) {
              setCustomResolutionSelected(true);
            }
          }}
          underlined
        />
        <TextButton
          text={translate("dock.display.streamQuality.apply")}
          onClick={() => {
            setDisplayResolution(CUSTOM_RESOLUTION_SIZES[selectedCustomResolution]);
            setCustomResolutionSelected(false);
          }}
          disabled={!customResolutionSelected}
        />
      </div>
      <h1>{translate("dock.display.sections.stream")}</h1>
      <p className="menu-item">{translate("dock.display.advanced.quality")}</p>
      <div className="menu-item">
        <Dropdown
          defaultSelected={
            streamPreferences.constantBitrate ? "Standard - x bit rate" : capitalize(streamPreferences.quality)
          }
          options={Object.keys(qualityOptions)}
          handleSelectedOptionChange={(option) => {
            if (streamPreferences.quality === option.toLowerCase()) return;
            streamPreferences.quality = option.toLowerCase();
            setStreamPreferences(streamPreferences);

            setIsStreamPreferencesChanged(!isStreamPreferencesChanged);
            saveItemToLocalStorage(LOCAL_STORAGE.advancedStreamSettings, streamPreferences);
            submitStreamPreference();
          }}
          underlined
        />
      </div>
      <p className="menu-item">{translate("dock.display.advanced.fps")}</p>
      <div className="menu-item">
        <Dropdown
          defaultSelected={streamPreferences.fps}
          options={FPS_OPTIONS}
          handleSelectedOptionChange={(option) => {
            streamPreferences.fps = option;
            setStreamPreferences(streamPreferences);

            setIsStreamPreferencesChanged(!isStreamPreferencesChanged);
            saveItemToLocalStorage(LOCAL_STORAGE.advancedStreamSettings, streamPreferences);
            submitStreamPreference();
          }}
          disabled={workstationType !== "GPU"}
          underlined
        />
      </div>
    </>
  );
};

export default DisplayTab;
