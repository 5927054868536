import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";

import { Icon, SlideModal } from "UI";
import { Loader, useAPI, isMobile } from "Utils";
import { CAPACITY_TYPES, REQUEST_STATUS } from "Constants/global.constants";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import routes from "Constants/Route.constants";
import { MAX_CAPACITY_BY_VENDOR_TYPE } from "Constants/AppStreaming.constants";
import { approveCapacityWarningConsent } from "Actions/AppStreaming.actions";

import ChooseRegion from "./ChooseRegion.component";
import StreamError from "../StreamError/StreamError.component";
import StreamLink from "../StreamLink/StreamLink.component";
import CapacitySelection from "../CapacitySelection/CapacitySelection.component";
import AppListCard from "../../Streams/AppListCard/AppListCard.component";

import "./CreateStreamModal.styles.scss";

const CREATE_STREAM_STEPS = {
  application: { step: 0, disabled: false },
  coverage: { step: 1, disabled: false },
  capacity: { step: 2, disabled: false },
  ready: { step: 3, disabled: true, showOnCurrentStep: true },
};

const CreateStreamModal = ({
  translate,
  setShowCreateStreamModal,
  selectedApp,
  capacities,
  createStreamAPI,
  getStreamsAPI,
  createStreamCTX,
  setSelectedStream,
  setSelectedApp,
  activeApplications,
  history,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCapacities, setSelectedCapacities] = useState([]);
  const [concurrentUser, setConcurrentUser] = useState(0);
  const [reserveCapacity, setReserveCapacity] = useState(1);
  const [stream, setStream] = useState();
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const [multipleCapacities, setMultipleCapacities] = useState(false);
  const [multipleSelection, setMultipleSelection] = useState({});
  const [capacityType, setCapacityType] = useState();
  const [showCapacityWarning, setShowCapacityWarning] = useState(false);
  const [capacityWarningConsent, setCapacityWarningConsent] = useState(false);

  const dispatch = useDispatch();

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const capacityWarningConsentApproved = useSelector(
    (state) => state.appStreaming.getVendorAccountCTX.data?.attributes?.capacity_warning_consent,
  );
  const maxCapacity = MAX_CAPACITY_BY_VENDOR_TYPE[vendorType];
  const defaultCapacity = MAX_CAPACITY_BY_VENDOR_TYPE.default;

  useEffect(() => {
    setSelectedCapacities([]);
    if (!isEmpty(selectedApp)) {
      setActiveTab(CREATE_STREAM_STEPS.coverage.step);
    }
  }, [selectedApp]);

  useEffect(() => {
    if (submitted) {
      if (createStreamCTX.status === REQUEST_STATUS.SUCCESS) {
        if (selectedApp.attributes?.is_bundled_app) {
          onClose();
        }
        setStream(createStreamCTX.data);
        setSelectedStream(createStreamCTX.data);
        setError(null);
      } else if (createStreamCTX.status === REQUEST_STATUS.FAILURE) {
        const errorCode = createStreamCTX.client_code;
        if (!error) {
          setError(errorCode);
        }
      } else {
        setError(null);
      }
    }
  }, [createStreamCTX.status]);

  useEffect(() => {
    setMultipleSelection(
      selectedCapacities.reduce((accumulator, value) => {
        return { ...accumulator, [value]: 0 };
      }, {}),
    );
  }, [selectedCapacities]);

  const {
    api: calculateRegionPricingAPI,
    data: regionPricing,
    isLoading: regionPricingLoading,
  } = useAPI({
    type: "post",
    endpoint: APP_STREAMING_API_ENDPOINTS.CALCULATE_REGION_PRICING,
    onSuccess: () => setShowPricingInfo(true),
  });

  const selectedRegionCount = selectedCapacities.length;
  const minComputeCost = selectedRegionCount > 0 ? regionPricing?.min_compute_cost : null;
  const maxComputeCost = selectedRegionCount > 0 ? regionPricing?.max_compute_cost : null;

  useEffect(() => {
    if (selectedCapacities.length !== 0) {
      setShowPricingInfo(false);
      calculateRegionPricingAPI({ application_id: selectedApp.id, regions: selectedCapacities });
    }
    if (selectedCapacities.length === 1 && multipleCapacities) setMultipleCapacities(false);
  }, [selectedCapacities]);

  const determineCapacityCount = (regionName) => {
    if (capacityType !== "always_on") {
      return defaultCapacity;
    }

    return !multipleCapacities ? parseInt(concurrentUser, 10) : multipleSelection[regionName];
  };

  const onReset = () => {
    setActiveTab(0);
    setConcurrentUser(0);
    setStream();
    setError(null);
    setSubmitted(false);
    setSelectedCapacities([]);
  };

  const onClose = () => {
    if (submitted) getStreamsAPI();
    setShowCreateStreamModal(false);
    setSelectedApp({});
  };

  const sidebarFooter = () => {
    if (!selectedApp.attributes?.is_bundled_app) return null;
    return (
      <div className="fast-launch-modal-sidebar-footer">
        <Icon name="lightning" className="gray-5" />
        <p>{translate("appStreamingModals.stream.bundledAppFooter")}</p>
      </div>
    );
  };

  const tabs = [
    {
      key: "step0",
      disabled: isEmpty(selectedApp),
      showSidebar: true,
      disablePrevious: true,
      hidePrevious: true,
      hideNext: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.stream.application.header")}</h1>
          <p>{translate("appStreamingModals.stream.application.description")}</p>
          <div className="application-selection">
            {activeApplications?.applications?.map((application) => {
              return (
                <AppListCard
                  key={application.attributes.name}
                  name={application.attributes.name}
                  logo={application.attributes.logo_url || application.attributes.dummy_logo_url}
                  onClick={() => {
                    setSelectedApp(application);
                    setActiveTab(CREATE_STREAM_STEPS.coverage.step);
                  }}
                  selected={parseInt(selectedApp.id, 10) === parseInt(application.id, 10)}
                  withoutCaret
                  bundledApp={application.attributes.is_bundled_app}
                />
              );
            })}
          </div>
        </>
      ),
    },
    {
      key: "step1",
      disabled: selectedCapacities.length === 0,
      showSidebar: true,
      content: (
        <ChooseRegion
          translate={translate}
          coveredRegions={capacities?.map((capacity) => capacity.region)}
          selectedCapacities={selectedCapacities}
          onRegionClick={(region) => {
            setSelectedCapacities((prevState) => _.xor(prevState, [region]));
          }}
          minSessionCostPerMinute={minComputeCost}
          maxSessionCostPerMinute={maxComputeCost}
          newDailyCost={regionPricing?.new_daily_datacenter_cost}
          regionPricingLoading={regionPricingLoading}
        />
      ),
      sidebarFooter: sidebarFooter(),
    },
    {
      key: "step2",
      showSidebar: true,
      disabled:
        !capacityType ||
        (capacityType === CAPACITY_TYPES.always_on && concurrentUser === 0) ||
        selectedCapacities.length === 0,
      content: (
        <CapacitySelection
          capacity={concurrentUser}
          setCapacity={setConcurrentUser}
          reserveCapacity={reserveCapacity}
          setReserveCapacity={setReserveCapacity}
          translate={translate}
          selectedRegionCount={selectedCapacities.length}
          selectedCapacities={selectedCapacities}
          cost={showPricingInfo && regionPricing?.total_datacenter_cost.toFixed(2)}
          dailyCost={regionPricing?.daily_datacenter_cost || ""}
          multipleSelection={multipleSelection}
          setMultipleSelection={setMultipleSelection}
          capacityType={capacityType}
          setCapacityType={setCapacityType}
          maxCapacity={maxCapacity}
          capacityWarningConsent={capacityWarningConsent}
          setCapacityWarningConsent={setCapacityWarningConsent}
          showCapacityWarning={showCapacityWarning}
        />
      ),
      overridePreviousAction: showCapacityWarning ? () => setShowCapacityWarning(false) : false,
      overrideNextAction:
        !capacityWarningConsentApproved && capacityType !== CAPACITY_TYPES.on_demand && !showCapacityWarning
          ? () => setShowCapacityWarning(true)
          : false,
      onSubmit: () => {
        const tmpCapacities = _.cloneDeep(capacities);
        const newCapacities = selectedCapacities.reduce((regions, regionName) => {
          const index = regions.findIndex((region) => region.region === regionName);
          const regionCapacity = [...regions];
          regionCapacity[index].total_capacity = determineCapacityCount();
          regionCapacity[index].capacity_type = capacityType;
          regionCapacity[index].reserve_capacity = capacityType === CAPACITY_TYPES.balanced ? reserveCapacity : 0;
          return regionCapacity;
        }, tmpCapacities);
        setSubmitted(true);

        if (capacityWarningConsent) {
          dispatch(approveCapacityWarningConsent());
        }

        createStreamAPI({
          appID: selectedApp.id,
          capacities: newCapacities,
        });
      },
      sidebarFooter: sidebarFooter(),
      overrideNextButtonText: translate("appStreamingModals.stream.step1.concurrentUser.action"),
    },
    {
      key: "step3",
      disabled: !submitted,
      disablePrevious: true,
      showSidebar: true,
      overrideStepName: createStreamCTX.status === REQUEST_STATUS.FAILURE && "Failed",
      content: (
        <>
          {stream && (
            <StreamLink
              setShowCreateStreamModal={setShowCreateStreamModal}
              translate={translate}
              onEdit={() => {
                history.push({
                  pathname: routes.appStreamingConfigureStream,
                  state: {
                    streamID: stream.attributes.id,
                  },
                });
              }}
              stream={stream}
              isPending={createStreamCTX.status === REQUEST_STATUS.PENDING}
              appID={selectedApp.id}
            />
          )}
          {createStreamCTX.status === REQUEST_STATUS.FAILURE && (
            <StreamError
              translate={translate}
              error={error}
              history={history}
              setShowModal={setShowCreateStreamModal}
              onReset={onReset}
            />
          )}
          {submitted && createStreamCTX.status === REQUEST_STATUS.PENDING && <Loader />}
        </>
      ),
    },
  ];

  return (
    <SlideModal
      className="new-stream-modal"
      closeAction={onClose}
      confirmAction={onClose}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={onClose}
      translate={translate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      loading={createStreamCTX.status === REQUEST_STATUS.PENDING || capacities?.length === 0}
      disablePrevious={submitted}
      sidebarHeader="Streams"
      steps={CREATE_STREAM_STEPS}
      currentStep={activeTab}
      big={!isMobile}
    />
  );
};

export default CreateStreamModal;
