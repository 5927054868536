import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BroadcastChannel } from "broadcast-channel";
import { useHotkeys } from "react-hotkeys-hook";
import FilesContainer from "Containers/Dashboard/Files.container";
import WorkstationCanvas from "Components/Workstation/WorkstationCanvas/WorkstationCanvas.component";
import UploadOverlay from "Components/Workstation/UploadOverlay/UploadOverlay.component";
import WorkstationDock from "Components/Workstation/WorkstationDock/WorkstationDock.component";
import ResizeButton from "Components/Workstation/ResizeButton/ResizeButton.component";
import {
  BalanceExceedModal,
  StreamPreferencesModal,
  SyncClipboardModal,
  FileUploadModal,
  FileDownloadModal,
  FreeUsageEndModal,
} from "Components/Workstation/Modals";
import WebsocketConnection from "Utils/Workstation/WebsocketConnection";
import { REQUEST_STATUS, LOCAL_STORAGE, DISK_LOCATIONS, STREAM_TYPE, UPLOAD_TYPES } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import {
  getWorkstationsAPI,
  getWorkstationCurrentSessionAPI,
  getNotificationAPI,
  updateWorkstationStatusAPI,
  updateWorkstationAutoStopConfigurationAPI,
} from "Actions/Dashboard/Workstations.actions";
import { approveUserPaymentMethodAPI, denyUserPaymentMethodAPI } from "Actions/Dashboard/Payment.actions";
import {
  setShowFileProgressBar,
  cancelFileUpload,
  downloadFile,
  downloadFileAPISuccess,
  uploadFilesToFolder,
  clearWorkstationUploadedFile,
  createFolder,
} from "Actions/Dashboard/Files.actions";
import { getItemFromLocalStorage, Logger, saveItemToLocalStorage, Tracker, isMobile } from "Utils";
import apiGenerator from "Utils/Helpers/api.helpers";
import { getMachineUsageHistoryAPI } from "Actions/Account.actions";
import { API_ENDPOINTS } from "Constants/api.constants";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import GameModeOnModal from "Components/Workstation/Modals/GameModeOnModal.component";
import KeyboardShortcuts from "Components/Workstation/WorkstationDock/KeyboardShortcuts/KeyboardShortcuts.component";
import FirstConnectionOnboardingModal from "Components/Workstation/Modals/FirstConnectionOnboardingModal.component";
import IframeModal from "Components/Workstation/Modals/IframeModal/IframeModal.component";
import { applyAdvancedStreamingSettings, applyScrollDirectionPreference } from "Utils/Helpers/streaming.helpers";
import { isSafari, isVrSupported } from "Utils/Helpers/browser.helpers";
import WorkstationCursor from "Components/Workstation/WorkstationCursor/WorkstationCursor.component";
import { ConfirmationModal } from "UI";
import {
  ToastNotificationContainer,
  ToastNotificationContent,
} from "Components/Workstation/ToastNotification/ToastNotification";
import KeyboardButton from "Components/Workstation/WorkstationDock/KeyboardButton/KeyboardButton.component";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import WebXRPolyfill from "webxr-polyfill";

const POLLING_INTERVAL = 60000;

export function Hotkey({ hotkey, callback }) {
  useHotkeys(hotkey, callback);
  return null;
}

class WorkstationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      loaderContent: "Connecting...",
      isDockOpen: false,
      isMicEnabled: false,
      showFileManager: false,
      terminateFileManager: true,
      showKeyboardShortcuts: false,
      showStreamPreferences: false,
      showClipboardModal: false,
      clipboardModalCopyMode: true,
      showFileUploadModal: false,
      showDownloadModal: false,
      showBalanceExceedModal: false,
      showGameModeOnModal: false,
      showFirstConnectionOnboardingModal: false,
      firstConnectionOnboardingModalShown: getItemFromLocalStorage(
        LOCAL_STORAGE.firstConnectionOnboardingModalShown,
        false,
      ),
      showDockOnboardingModal: false,
      showVrDeviceDetectedModal: false,
      customResolution: getItemFromLocalStorage(LOCAL_STORAGE.customResolution, null),
      gameModeEnabled: false,
      isFolder: false,
      iframeSrc: null,
      cursorX: 0,
      cursorY: 0,
      cursorImage: "",
      showWebRTCConnectionFailureInformationModal: false,
      vrState: "disabled",
      vrInstallationState: {},
    };

    if (props.workstations.getWorkstationsCTX.status !== REQUEST_STATUS.SUCCESS) {
      const { getWorkstationsAPI } = this.props;
      getWorkstationsAPI();
    }
    this.setPollingInterval();
  }

  componentDidMount() {
    const {
      workstations,
      setShowFileProgressBar,
      account,
      getWorkstationCurrentSessionAPI, // eslint-disable-line
      getNotificationAPI,
      getMachineUsageHistoryAPI,
    } = this.props;

    if (workstations.getWorkstationsCTX.status === REQUEST_STATUS.SUCCESS) {
      this.applyStreamingPreferences();
      this.initializeSocket();
    }

    setShowFileProgressBar(false);
    getWorkstationCurrentSessionAPI();
    getNotificationAPI();

    if (!this.duplicateTabChannel) {
      this.duplicateTabChannel = new BroadcastChannel("duplicate-tab-channel");
      this.duplicateTabChannel.postMessage("Workstation open at another tab.");
      this.duplicateTabChannel.onmessage = () => {
        this.onDuplicateTab();
      };
    }

    this.videoElement = document.getElementById("player");

    document.addEventListener("visibilitychange", this.onVisibilityStateChange, true);

    /* Firefox */
    document.addEventListener("mozfullscreenchange", this.handleFullScreenChange);

    // window.addEventListener("beforeunload", (event) => {
    //   event.preventDefault();
    //   window.event.returnValue = null;
    // });

    /* Chrome, Safari and Opera */
    document.addEventListener("webkitfullscreenchange", this.handleFullScreenChange);

    if (isSafari) {
      window.addEventListener("orientationchange", this.onOrientationChange);
    } else {
      window.screen.orientation.addEventListener("change", this.onOrientationChange);
    }
    getMachineUsageHistoryAPI(account.accessToken);
    this.pollyFill = new WebXRPolyfill();
  }

  componentDidUpdate(prevProps, prevState) {
    const { connected, showBalanceExceedModal } = this.state;
    const { workstations, files, clearWorkstationUploadedFile, history } = this.props;

    const gpuCount = workstations.getWorkstationCurrentSessionCTX?.data?.attributes?.machine_type?.attributes?.gpu || 0;

    if (
      !prevState.connected &&
      connected &&
      !getItemFromLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled) &&
      gpuCount > 0 &&
      !isMobile
    ) {
      isVrSupported().then((isSupported) => {
        if (isSupported) {
          this.setShowVrDeviceDetectedModal(true);
        }
      });
    }

    if (
      prevProps.workstations.getWorkstationsCTX.status !== REQUEST_STATUS.SUCCESS &&
      workstations.getWorkstationsCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      let isWorkstationAvailable = false;
      if (
        workstations.workstations &&
        workstations.workstations[0] &&
        workstations.workstations[0].attributes.machine.attributes &&
        workstations.workstations[0].attributes.machine.attributes.password
      ) {
        const friendlyStatus = workstations.workstations[0].attributes.machine.attributes.friendly_status;
        isWorkstationAvailable = friendlyStatus === "ready";
      }
      if (!isWorkstationAvailable) {
        clearInterval(this.pollingInterval);
        history.push(routes.home);
      }
    }

    if (
      prevProps.workstations.getWorkstationsCTX.status !== REQUEST_STATUS.SUCCESS &&
      prevProps.workstations.getWorkstationsCTX.status !== REQUEST_STATUS.PENDING &&
      workstations.getWorkstationsCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      this.applyStreamingPreferences();
      this.initializeSocket();
    }

    if (workstations.notifications.length > 0 && !showBalanceExceedModal) {
      const currentSessionId = parseInt(workstations.getWorkstationCurrentSessionCTX.data.id, 10);
      const currentSessionNotification = workstations.notifications?.find((notification) => {
        return notification?.attributes?.machine_session_id === currentSessionId;
      });
      if (currentSessionNotification) {
        this.setShowBalanceExceedModal(true);
      }
    }

    if (prevProps.workstations.notifications.length > 0 && workstations.notifications.length === 0) {
      this.setShowBalanceExceedModal(false);
    }
    this.handleFirstConnectionOnboardingModal();

    if (Object.keys(files.workstationUploadFilesCTX.uploadedFiles) !== 0 && connected) {
      const { uploadedFiles } = files.workstationUploadFilesCTX;
      Object.keys(uploadedFiles).forEach((fileName) => {
        this.websocketConnection.sendDownloadFileEvent(uploadedFiles[fileName].downloadUrl, fileName);
        clearWorkstationUploadedFile(fileName);
      });
    }
  }

  componentWillUnmount() {
    const { setShowFileProgressBar } = this.props;
    setShowFileProgressBar(true);

    Logger.log("Workstation|ComponentWillUnmount");
    if (this.websocketConnection) {
      // Override onclose handler and don't reinit socket.
      this.websocketConnection.closeSockets();
      this.websocketConnection.stopStreaming();
    }

    clearInterval(this.pollingInterval);

    document.removeEventListener("visibilitychange", this.onVisibilityStateChange, true);

    /* Firefox */
    document.removeEventListener("mozfullscreenchange", this.resizeWindow);
    /* Chrome, Safari and Opera */
    document.removeEventListener("webkitfullscreenchange", this.resizeWindow);
    if (isSafari) {
      window.removeEventListener("orientationchange", this.resizeWindow);
    } else {
      window.screen.orientation.removeEventListener("change", this.resizeWindow);
    }

    this.duplicateTabChannel.close();
  }

  initializeSocket = () => {
    if (this.props.workstations.workstations.length > 0) {
      const {
        password,
        auto_stop_enabled: autoStopEnabled,
        auto_stop_threshold: autoStopThreshold,
      } = this.props.workstations.workstations[0].attributes.machine.attributes;

      if (password) {
        if (this.websocketConnection && this.websocketConnection.websocket) {
          // Closing socket will restart it automatically.
          Logger.log("Close socket");
          this.websocketConnection.closeSockets();
          this.websocketConnection.restartConnection();
        } else {
          this.websocketConnection = new WebsocketConnection({
            password,
            controlWorkstationStatus: this.controlWorkstationStatus,
            showNotification: this.showToastNotification,
            autoStopEnabled,
            autoStopThreshold,
            setConnectingState: this.setConnectingState,
            onConnection: this.onConnection,
            onInitConnection: this.onInitConnection,
            onDownload: this.onDownload,
            customResolution: this.state.customResolution,
            setIframeSrc: this.setIframeSrc,
            onDuplicateTab: this.onDuplicateTab,
            streamType: STREAM_TYPE.workstation,
            machineDetails: this.props.workstations.workstations[0],
            changeCursorPosition: this.changeCursorPosition,
            setCursorImage: this.setCursorImage,
            onWebRTCConnectionFailure: this.onWebRTCConnectionFailure,
            setVrState: this.setVrState,
            setVrInstallationState: this.setVrInstallationState,
          });
        }
      }
    }
  };

  changeCursorPosition = (x, y) => {
    this.setState({ cursorX: x, cursorY: y });
  };

  setCursorImage = (img) => {
    this.setState({ cursorImage: img });
  };

  setPollingInterval = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = setInterval(this.getWorkstations, POLLING_INTERVAL);
  };

  getWorkstations = () => {
    const { account, getWorkstationsAPI } = this.props;

    getWorkstationsAPI(account.accessToken);
  };

  resizeWindow = () => {
    Tracker.time({ type: "connect", start: true });
    this.setState(
      {
        loaderContent: "Setting Screen Resolution...",
        showFileUploadModal: false,
        connected: false,
      },
      () => {
        this.initializeSocket();
      },
    );
  };

  ctrlAltDel = () => {
    this.websocketConnection.sendCtrlAltDeleteEvent();
  };

  pasteToRemote = () => {
    this.websocketConnection.pasteToRemote();
  };

  copyFromRemote = () => {
    this.websocketConnection.copyFromRemote();
  };

  showToastNotification = (props) => {
    const { header, description } = props;
    toast.dismiss();
    toast(<ToastNotificationContent header={header} description={description} />);
  };

  stopWorkstation = () => {
    this.props.updateWorkstationStatusAPI("stop");
  };

  controlWorkstationStatus = () => {
    this.props.getWorkstationsAPI(this.props.account.accessToken);
  };

  showScreenKeyboard = () => {
    this.websocketConnection.sendShowScreenKeyboardEvent();
  };

  handleFullScreenChange = () => {
    if (this.state.customResolution?.localResolution) {
      this.resizeWindow();
    }
  };

  toggleFullscreen = () => {
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
      document.mozFullScreenElement /* Firefox syntax */ ||
      document.msFullscreenElement /* IE/Edge syntax */
    ) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  };

  // enterFullScreen
  enterFullScreen = () => {
    const docElement = document.documentElement;
    // Toggle fullscreen if not already in fullscreen
    if (docElement.requestFullScreen) {
      docElement.requestFullScreen();
    } else if (docElement.mozRequestFullScreen) {
      docElement.mozRequestFullScreen();
    } else if (docElement.webkitRequestFullScreen) {
      docElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (docElement.msRequestFullscreen) {
      docElement.msRequestFullscreen();
    }
  };

  /* Close fullscreen */
  exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  enterPassword = () => {
    this.websocketConnection.sendEnterPasswordEvent();
  };

  onConnection = ({ timeToConnect }) => {
    this.setState({ connected: true });
    if (timeToConnect) {
      const api = apiGenerator("post", this.props.account.accessToken);
      // just fire and forget
      api(API_ENDPOINTS.ADD_CONNECT_TIME, { connection_time: timeToConnect });
    }

    // Stop ebs warmup
    const { workstations } = this.props;
    if (workstations.workstations) {
      const workstationLastDiskLocation =
        workstations?.workstations[0]?.attributes?.machine?.attributes?.last_started_from;
      if (workstationLastDiskLocation === DISK_LOCATIONS.ebs) {
        this.websocketConnection.sendStopWarmUpEvent();
      }
    }
  };

  onInitConnection = () => {
    this.setConnectingState();
    this.setState({
      connected: false,
    });
  };

  onVisibilityStateChange = () => {
    // Before web socket init people can change tab visibility
    if (!this.websocketConnection) {
      return;
    }

    if (document.visibilityState === "visible" && this.websocketConnection.isFirstFrameMuxingCompleted) {
      this.websocketConnection.requestKeyFrame();
    }
  };

  setConnectingState = (content = "Connecting...") => {
    this.setState({ loaderContent: content });
  };

  setIsDockOpen = (isDockOpen) => {
    this.setState({ isDockOpen });
  };

  setIsFolder = (isFolder) => {
    this.setState({ isFolder });
  };

  setShowFileManager = (showFileManager) => {
    const { setShowFileProgressBar } = this.props;

    if (showFileManager !== this.state.showFileManager) {
      if (showFileManager) {
        clearTimeout(this.closeFileManagerTimeout);
      } else {
        this.closeFileManagerTimeout = setTimeout(() => {
          this.setState({ terminateFileManager: true });
        }, 800);
      }
      setShowFileProgressBar(showFileManager);

      this.setDisableKeyboardActions(showFileManager);

      this.setState({
        showFileManager,
        terminateFileManager: false,
      });
    }
  };

  setDisableKeyboardActions = (disableKeyboardActions) => {
    if (this.websocketConnection) {
      this.websocketConnection.setDisableKeyboardActions(disableKeyboardActions);
    }
  };

  setShowKeyboardShortcuts = (showKeyboardShortcuts) => {
    this.setState({ showKeyboardShortcuts });
  };

  setShowStreamPreferences = (flag) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({ showStreamPreferences: flag });
    }
  };

  setShowClipboardModal = (flag, copyMode) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({
        showClipboardModal: flag,
        clipboardModalCopyMode: copyMode,
      });
    }
  };

  setShowFileUploadModal = (showFileUploadModal) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({ showFileUploadModal });
    }
  };

  setShowDownloadModal = (showDownloadModal) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({ showDownloadModal });
    }
  };

  setShowBalanceExceedModal = (showBalanceExceedModal) => {
    this.setState({ showBalanceExceedModal });
  };

  setIframeSrc = (iframeSrc) => {
    this.setState({ iframeSrc });
  };

  setShowGameModeOnModal = (showGameModeOnModal) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({ showGameModeOnModal });
      if (showGameModeOnModal) {
        saveItemToLocalStorage(LOCAL_STORAGE.showedGameModeModal, true);
      }
    }
  };

  setShowDockOnboardingModal = (showDockOnboardingModal) => {
    this.setState({ showDockOnboardingModal });
  };

  setShowVrDeviceDetectedModal = (showVrDeviceDetectedModal) => {
    this.setState({ showVrDeviceDetectedModal });
  };

  firefoxSafariOnPaste = (event) => {
    event.preventDefault();
    this.websocketConnection.pasteTextToRemote(event.clipboardData.getData("text"));
    this.setState({ showClipboardModal: false });
  };

  firefoxSafariOnCopy = (event) => {
    event.preventDefault();
    // TODO check this function
    this.websocketConnection.pasteTextToRemote(
      event.clipboardData.setData("text/plain", sessionStorage.getItem("clipboardData")),
    );
    this.setState({ showClipboardModal: false });
  };

  changeSoundState = (state) => {
    saveItemToLocalStorage(LOCAL_STORAGE.soundEnabled, state);
    this.websocketConnection.audioStateChanged();
  };

  toggleMic = () => {
    const { isMicEnabled } = this.state;
    this.websocketConnection.changeMicrophoneState(!isMicEnabled);
    this.setState({
      isMicEnabled: !isMicEnabled,
    });
  };

  applyStreamingPreferences = (options) => {
    const { workstations } = this.props;
    let workstationType = "GPU";
    const currentSessionAttributes = workstations.getWorkstationCurrentSessionCTX.data.attributes;

    if (currentSessionAttributes) {
      if (currentSessionAttributes.machine_type.attributes.gpu === 0) {
        workstationType = "CPU";
      }
    }

    applyAdvancedStreamingSettings(workstationType, options);
  };

  submitStreamPreference = () => {
    this.applyStreamingPreferences();
    this.setShowStreamPreferences(false);
    this.setState(
      {
        loaderContent: "Applying Stream Preferences...",
        isDockOpen: false,
      },
      () => {
        if (this.websocketConnection) {
          this.websocketConnection.closeSockets();
          this.websocketConnection.restartConnection();
        }
      },
    );
  };

  setDisplayResolution = (resolution) => {
    if (resolution) {
      saveItemToLocalStorage(LOCAL_STORAGE.customResolution, resolution, true);
      this.websocketConnection.setDisplayResolution(resolution);
      this.setState({
        customResolution: resolution,
      });
    } else {
      this.setState({
        customResolution: null,
      });
      removeItemFromLocalStorage(LOCAL_STORAGE.customResolution);
      this.websocketConnection.setDisplayResolution(null);
    }

    this.resizeWindow();
  };

  changeVerticalScrollDirection = (inverseDirection) => {
    applyScrollDirectionPreference(inverseDirection);
    this.websocketConnection.changeScrollPreference();
  };

  changeSwitchCmdPreference = (switchCmdPreference) => {
    this.websocketConnection.changeCmdPreference(switchCmdPreference);
  };

  setVrState = (vrState) => {
    this.setState({ vrState });
  };

  setVrInstallationState = (vrInstallationState) => {
    this.setState({ vrInstallationState });
  };

  /*
   *  Closed websocket upload. AWS upload faster than direct websocket upload
   */

  handleToWorkstationDrop = (files) => {
    const { uploadFilesToFolder } = this.props;
    if (files.length > 0) {
      uploadFilesToFolder({ files, uploadType: UPLOAD_TYPES.WORKSTATION_UPLOAD });
      this.setShowFileUploadModal(true);
      // this.websocketConnection.onDrop(event);
    }
  };

  handleToFilesDrop = (files) => {
    const { uploadFilesToFolder } = this.props;
    if (files.length > 0) {
      uploadFilesToFolder({ files, isRecentFiles: true });
    }
    this.setIsDockOpen(true);
  };

  onDownload = (file) => {
    if (file) {
      const { downloadFileAPISuccess } = this.props;
      downloadFileAPISuccess(file);
      this.setState({
        showDownloadModal: true,
      });
    }
  };

  changeGameMode = () => {
    const { gameModeEnabled } = this.state;
    const { translate } = this.props;
    this.websocketConnection.changeGameMode(!gameModeEnabled);
    this.setState({
      gameModeEnabled: !gameModeEnabled,
    });
    if (!gameModeEnabled) {
      setTimeout(() => {
        this.showToastNotification({
          header: translate("gameMode.enabled.header"),
          description: translate("gameMode.enabled.description"),
        });
      }, 10);
      setTimeout(() => {
        document.addEventListener("pointerlockchange", this.onPointerLockChanged, false);
      }, 100);
    }
  };

  onPointerLockChanged = () => {
    const { translate } = this.props;
    document.removeEventListener("pointerlockchange", this.onPointerLockChanged, false);
    this.showToastNotification({
      header: translate("gameMode.disabled.header"),
      description: translate("gameMode.disabled.description"),
    });
    this.changeGameMode();
  };

  handleCloseFirstConnectionOnboardingModal = () => {
    this.setState({ showFirstConnectionOnboardingModal: false });
    localStorage.setItem(LOCAL_STORAGE.firstConnectionOnboardingModalShown, true);
    this.setIsDockOpen(true);
    setTimeout(() => {
      this.setShowDockOnboardingModal(true);
    }, 1000);
  };

  handleFirstConnectionOnboardingModal = () => {
    const { account } = this.props;
    const { firstConnectionOnboardingModalShown } = this.state;
    if (
      !isMobile &&
      !firstConnectionOnboardingModalShown &&
      account.machineUsageHistoryCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      if (account.machineUsageHistoryCTX.data.length === 1) {
        this.setState({ showFirstConnectionOnboardingModal: true });
      }
      this.setState({ firstConnectionOnboardingModalShown: true });
    }
  };

  turnOff = () => {
    this.stopWorkstation();
    setTimeout(() => {
      this.props.history.push(routes.home);
    }, 2000);
  };

  onDuplicateTab = () => {
    this.props.history.push(routes.workstationDuplicate);
  };

  onWebRTCConnectionFailure = () => {
    this.props.history.push(routes.workstationConnectionFailed);
  };

  closeSoundProcess = () => {
    this.changeSoundState(false);
    const { isMicEnabled } = this.state;
    if (isMicEnabled) {
      this.toggleMic();
    }
  };

  handleAutoTurnOffChanged = (autoTurnOff) => {
    const { updateWorkstationAutoStopConfigurationAPI } = this.props;
    updateWorkstationAutoStopConfigurationAPI(autoTurnOff);
  };

  onReturnDashboardClick = () => {
    const { history } = this.props;

    history.push(routes.home);
  };

  onOrientationChange = () => {
    if (this.state.customResolution?.localResolution) {
      this.resizeWindow();
    }
  };

  toggleVRMode = () => {
    this.websocketConnection.sendToggleVRModeEvent();
  };

  toggleVREnvironment = (state) => {
    this.websocketConnection.toggleVREnvoriment(state);
  };

  render() {
    const {
      connected,
      isDockOpen,
      isMicEnabled,
      isFolder,
      loaderContent,
      customResolution,
      showFileManager,
      terminateFileManager,
      showKeyboardShortcuts,
      showStreamPreferences,
      showClipboardModal,
      askShutDownConfirmation,
      showFileUploadModal,
      clipboardModalCopyMode,
      showDownloadModal,
      showBalanceExceedModal,
      showGameModeOnModal,
      gameModeEnabled,
      showFirstConnectionOnboardingModal,
      iframeSrc,
      cursorX,
      cursorY,
      cursorImage,
      showDockOnboardingModal,
      showWebRTCConnectionFailureInformationModal,
      vrState,
      vrInstallationState,
      showVrDeviceDetectedModal,
    } = this.state;

    const { workstations, files: filesCTX, cancelFileUpload, translate } = this.props;

    let workstationType = "GPU";
    const currentSessionAttributes = workstations.getWorkstationCurrentSessionCTX.data.attributes;
    if (currentSessionAttributes) {
      if (currentSessionAttributes.machine_type.attributes.gpu === 0) {
        workstationType = "CPU";
      }
    }
    const machineHasMultiGpu = currentSessionAttributes?.machine_type?.attributes?.gpu > 1;

    return (
      <div className="workstation-container">
        <Hotkey
          hotkey="ctrl+shift+l+s"
          callback={() => {
            this.websocketConnection.testHelper.prepareLatencyTest();
          }}
        />
        <WorkstationCanvas
          isConnected={connected}
          loaderContent={loaderContent}
          machineHasMultiGpu={machineHasMultiGpu}
          translate={translate}
          showConnectionTransition
        />
        <WorkstationDock
          {...this.props}
          isDockReady={workstations.workstations && workstations.workstations[0]}
          currentSessionAttributes={currentSessionAttributes}
          isDockOpen={isDockOpen}
          isMicEnabled={isMicEnabled}
          workstationType={workstationType}
          setIsDockOpen={this.setIsDockOpen}
          connected={connected}
          uploadFiles={filesCTX.uploadFilesCTX.uploadFiles}
          setShowKeyboardShortcuts={this.setShowKeyboardShortcuts}
          ctrlAltDel={this.ctrlAltDel}
          displayResolution={customResolution}
          setDisplayResolution={this.setDisplayResolution}
          resizeWindow={this.resizeWindow}
          stopWorkstation={this.stopWorkstation}
          showScreenKeyboard={this.showScreenKeyboard}
          pasteToRemote={this.pasteToRemote}
          copyFromRemote={this.copyFromRemote}
          toggleFullscreen={this.toggleFullscreen}
          enterPassword={this.enterPassword}
          machine={workstations.workstations && workstations.workstations[0]?.attributes?.machine}
          showFileManager={showFileManager}
          setShowGameModeOnModal={this.setShowGameModeOnModal}
          setShowDownloadModal={this.setShowDownloadModal}
          setShowFileManager={this.setShowFileManager}
          showDockOnboardingModal={showDockOnboardingModal}
          setShowDockOnboardingModal={this.setShowDockOnboardingModal}
          setDisableKeyboardActions={this.setDisableKeyboardActions}
          setShowStreamPreferences={this.setShowStreamPreferences}
          setShowClipboardModal={this.setShowClipboardModal}
          askShutDownConfirmation={askShutDownConfirmation}
          turnOff={this.turnOff}
          getWorkstationCurrentSessionCTX={workstations && workstations.getWorkstationCurrentSessionCTX}
          changeSoundState={this.changeSoundState}
          setShowFileUploadModal={this.setShowFileUploadModal}
          changeVerticalScrollDirection={this.changeVerticalScrollDirection}
          changeSwitchCmdPreference={this.changeSwitchCmdPreference}
          gameModeEnabled={gameModeEnabled}
          changeGameMode={this.changeGameMode}
          toggleMic={this.toggleMic}
          submitStreamPreference={this.submitStreamPreference}
          webRTCToggleHandler={this.webRTCToggleHandler}
          handleAutoTurnOffChanged={this.handleAutoTurnOffChanged}
          onReturnDashboardClick={this.onReturnDashboardClick}
          toggleVRMode={this.toggleVRMode}
          toggleVREnvironment={this.toggleVREnvironment}
          vrState={vrState}
          vrInstallationState={vrInstallationState}
          sendIsVrToolsRunningEvent={this.websocketConnection?.sendIsVrToolsRunningEvent}
          showVrDeviceDetectedModal={showVrDeviceDetectedModal}
          setShowVrDeviceDetectedModal={this.setShowVrDeviceDetectedModal}
          applyVrBitrate={this.websocketConnection?.applyVrBitrate}
        />
        {showKeyboardShortcuts && (
          <KeyboardShortcuts
            websocketConnection={this.websocketConnection}
            setShowKeyboardShortcuts={this.setShowKeyboardShortcuts}
          />
        )}
        <ToastNotificationContainer />
        {terminateFileManager && !showFileUploadModal && (
          <UploadOverlay
            onToWorkstationDrop={this.handleToWorkstationDrop}
            onToFilesDrop={this.handleToFilesDrop}
            setIsFolder={this.setIsFolder}
            translate={translate}
          />
        )}
        {!terminateFileManager && (
          <div className={`workstation-file-manager-container ${showFileManager ? "open" : ""}`}>
            <FilesContainer translate={translate} />
          </div>
        )}
        {showStreamPreferences && (
          <StreamPreferencesModal
            setShowStreamPreferences={this.setShowStreamPreferences}
            submitStreamPreference={() => {
              localStorage.setItem(LOCAL_STORAGE.webRTCApproval, true);
              this.webRTCToggleHandler();
            }}
            translate={translate}
          />
        )}
        {connected && showClipboardModal && (
          <SyncClipboardModal
            setShowClipboardModal={this.setShowClipboardModal}
            clipboardModalCopyMode={clipboardModalCopyMode}
            firefoxSafariOnPaste={this.firefoxSafariOnPaste}
            firefoxSafariOnCopy={this.firefoxSafariOnCopy}
            translate={translate}
          />
        )}
        {connected && showFileUploadModal && (
          <FileUploadModal
            files={filesCTX.uploadFilesCTX.uploadFiles}
            onFileDrop={this.handleToWorkstationDrop}
            setShowFileUploadModal={this.setShowFileUploadModal}
            createFolder={createFolder}
            uploadFilesToFolder={uploadFilesToFolder}
            cancelFileUpload={cancelFileUpload}
            descriptionText={translate("modals.workstationFileUpload.description")}
            isFolder={isFolder}
            translate={translate}
            isInWorkstation
          />
        )}
        {connected && customResolution?.localResolution && <ResizeButton resizeWindow={this.resizeWindow} />}
        {connected && showDownloadModal && (
          <FileDownloadModal
            fileName={filesCTX.downloadFileCTX.name}
            fileSize={filesCTX.downloadFileCTX.size}
            downloadUrl={filesCTX.downloadFileCTX.url}
            setShowDownloadModal={this.setShowDownloadModal}
            translate={translate}
          />
        )}
        <WorkstationCursor visible={gameModeEnabled} x={cursorX} y={cursorY} cursorImage={cursorImage} />
        {connected && showBalanceExceedModal && (
          <BalanceExceedModal
            setShowBalanceExceedModal={this.setShowBalanceExceedModal}
            notificationId={workstations?.notifications?.[0]?.id}
            translate={translate}
          />
        )}
        {connected && showGameModeOnModal && (
          <GameModeOnModal
            setShowGameModeOnModal={this.setShowGameModeOnModal}
            changeGameMode={this.changeGameMode}
            translate={translate}
          />
        )}
        {connected && showFirstConnectionOnboardingModal && (
          <FirstConnectionOnboardingModal
            translate={translate}
            onExit={this.handleCloseFirstConnectionOnboardingModal}
          />
        )}
        <FreeUsageEndModal
          {...this.props}
          getWorkstationCurrentSessionCTX={workstations && workstations.getWorkstationCurrentSessionCTX}
          translate={translate}
        />
        {iframeSrc && <IframeModal setShowIframeModal={this.setIframeSrc} src={iframeSrc} />}
        {showWebRTCConnectionFailureInformationModal && (
          <ConfirmationModal
            headerText={translate("modals.WebRTCConnectionFailure.header")}
            descriptionText={translate("modals.WebRTCConnectionFailure.description")}
            confirmText={translate("modals.WebRTCConnectionFailure.confirm")}
            confirmAction={() => {
              this.setState({ showWebRTCConnectionFailureInformationModal: false });
            }}
            closeAction={() => {
              this.setState({ showWebRTCConnectionFailureInformationModal: false });
            }}
          />
        )}
        {connected && isMobile && <KeyboardButton setShowKeyboardShortcuts={this.setShowKeyboardShortcuts} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  workstations: state.workstations,
  files: state.files,
});

const actionCreators = {
  cancelFileUpload,
  clearWorkstationUploadedFile,
  setShowFileProgressBar,
  downloadFile,
  downloadFileAPISuccess,
  uploadFilesToFolder,
  getWorkstationsAPI,
  getWorkstationCurrentSessionAPI,
  getNotificationAPI,
  updateWorkstationStatusAPI,
  updateWorkstationAutoStopConfigurationAPI,
  approveUserPaymentMethodAPI,
  denyUserPaymentMethodAPI,
  getMachineUsageHistoryAPI,
};

export default connect(mapStateToProps, actionCreators)(WorkstationContainer);
