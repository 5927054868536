import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Modal, Icon } from "UI";
import { AddBalanceForm, AddPaymentMethodModal, PaymentDetails, Payment3DSecureModal } from "Utils";
import { APP_SCOPES, REQUEST_STATUS } from "Constants/global.constants";
import { normalizeCredits } from "Utils/Helpers/functions.helpers";
import { useAppScope } from "Utils/Hooks/useAppScope";

import { addBalanceAPI, resetAddBalanceCTX } from "Actions/Dashboard/Payment.actions";

import "./AddBalanceModal.style.scss";

const USER_PRICES = [10, 50, 100, 250];
const VENDOR_PRICES = [25, 50, 500, 1000];

const AddBalanceModal = (props) => {
  const {
    addBalanceAPI,
    resetAddBalanceCTX,
    payment,
    setShowAddBalanceModal,
    isTeamBalance,
    onAddBalanceSuccess,
    translate,
  } = props;
  const { addBalanceCTX } = payment;

  const { appScope } = useAppScope();
  const prices = appScope === APP_SCOPES.user ? USER_PRICES : VENDOR_PRICES;

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = React.useState(false);
  const [showPayment3DSecure, setShowPayment3DSecure] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState(0);
  const [selectedBalancePrice, setSelectedBalancePrice] = useState(0);
  const [error, setError] = useState(false);

  const balanceAmount = selectedBalance;

  const paymentLineItems = [
    {
      key: 1,
      name: translate("paymentDetails.balance"),
      amount: balanceAmount,
      hide: !selectedBalance || isTeamBalance,
    },
    {
      key: 1,
      name: translate("paymentDetails.credits", { credits: normalizeCredits(selectedBalance) }),
      amount: balanceAmount,
      hide: !selectedBalance || !isTeamBalance,
    },
    {
      key: 2,
      name: translate("paymentDetails.processingFee"),
      amount: selectedBalancePrice && selectedBalancePrice.toFixed(2),
      hide: !selectedBalance,
    },
  ];

  useEffect(() => {
    resetAddBalanceCTX();
  }, []);

  useEffect(() => {
    if (addBalanceCTX.status === REQUEST_STATUS.FAILURE) {
      const errorCode = addBalanceCTX.client_code;
      // Set error message
      if (!error) {
        setError({ errorCode });
      }
    } else {
      setError(false);
    }
  }, [addBalanceCTX]);

  useEffect(() => {
    if (payment.payment3DSecureCTX.status === REQUEST_STATUS.PENDING) {
      if (payment.payment3DSecureCTX.data && props.payment.payment3DSecureCTX.data.payment_required === true) {
        setShowPayment3DSecure(true);
      }
    }
  }, [payment.payment3DSecureCTX.status]); //eslint-disable-line

  useEffect(() => {
    const paymentMethods = payment.getPaymentMethodsCTX.data;

    if (!paymentMethods || paymentMethods.length === 0) {
      setShowAddPaymentMethodModal(true);
    } else {
      setShowAddPaymentMethodModal(false);
    }
  }, [payment.getPaymentMethodsCTX.data]);

  const onCancel = () => {
    setShowAddBalanceModal(false);
  };

  const onPaymentButtonClick = () => {
    addBalanceAPI(parseFloat(selectedBalance), isTeamBalance);
  };

  if (showAddPaymentMethodModal) {
    return (
      <AddPaymentMethodModal
        onClose={() => {
          setShowAddBalanceModal(false);
          setShowAddPaymentMethodModal(false);
          if (onAddBalanceSuccess) {
            onAddBalanceSuccess();
          }
        }}
        setShowAddPaymentMethodModal={setShowAddPaymentMethodModal}
        translate={translate}
      />
    );
  }

  const handle3DSecureFailure = (errorMessage) => {
    setError({ message: errorMessage });
    setShowPayment3DSecure(false);
  };

  return (
    <>
      <Modal
        closeOnOverlayClick
        closeAction={onCancel}
        topRightIcon="close"
        topRightIconAction={onCancel}
        headerText={
          isTeamBalance ? translate("teamAddBalanceModal.headerText") : translate("addBalanceModal.headerText")
        }
        descriptionText={
          isTeamBalance
            ? translate("teamAddBalanceModal.descriptionText")
            : translate("addBalanceModal.descriptionText")
        }
        buttonText={translate("addBalanceModal.buttonText", {
          paymentAmount: selectedBalance ? `$${(selectedBalancePrice + balanceAmount || 0).toFixed(2)}` : "",
        })}
        buttonAction={onPaymentButtonClick}
        buttonDisabled={!selectedBalance || addBalanceCTX.status === REQUEST_STATUS.PENDING}
        errorText={
          error &&
          (error.message ? (
            error.message
          ) : (
            <Translate
              id={`addBalanceModal.error.${error.errorCode}`}
              options={{ onMissingTranslation: () => translate("addBalanceModal.error.default") }}
            />
          ))
        }
        errorable
      >
        <div className="add-balance-modal-content">
          <div>
            <AddBalanceForm
              small
              defaultBalance={prices[1]}
              prices={prices}
              setBalance={setSelectedBalance}
              setBalancePrice={setSelectedBalancePrice}
              translate={translate}
              addBalanceCTX={addBalanceCTX}
            />
            {isTeamBalance && (
              <div className="credit-conversion">
                <Icon name="info" color="aqua-dark" />
                <p>{translate("creditConversionRate")}</p>
              </div>
            )}
          </div>
          <PaymentDetails items={paymentLineItems} headerText={translate("addBalanceModal.paymentDetailsHeader")} />
        </div>
      </Modal>
      {showPayment3DSecure && (
        <Payment3DSecureModal
          onSuccess={() => {
            setShowPayment3DSecure(false);
            setShowAddBalanceModal(false);
          }}
          onFailure={handle3DSecureFailure}
          setShowPayment3DSecure={setShowPayment3DSecure}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  payment: state.payment,
});

const actionCreators = {
  addBalanceAPI,
  resetAddBalanceCTX,
};

export default connect(mapStateToProps, actionCreators)(AddBalanceModal);
