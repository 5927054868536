import React, { useEffect, useRef } from "react";
import { GAME_ENGINES, STREAM_TYPES } from "Constants/AppStreaming.constants";
import { OS } from "Constants/global.constants";
import { Dropdown, HoverableTooltip, Icon, Input, Switch } from "UI";

import "./AdvancedStep.styles.scss";
import { Translate } from "react-localize-redux";

const AdvancedStep = ({ streamConfigs, setStreamConfigs, streamType, initialStreamConfigs, translate }) => {
  const {
    autoStart,
    restartApplication,
    pixelStreamingEnabled,
    os,
    sumOfAllUsedCapacities,
    collectApplicationLogs,
    gameEngine,
    companyName,
    productName,
    autoTurnOffDuration,
  } = streamConfigs;

  const advancedConfigsDisabled =
    streamType !== STREAM_TYPES.regular || pixelStreamingEnabled || os !== OS.windows || sumOfAllUsedCapacities > 0;

  const collectApplicationLogsDisabled = streamType !== STREAM_TYPES.regular || os !== OS.windows;
  const productNameRef = useRef(null);
  const companyNameRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (collectApplicationLogs) {
      if (gameEngine === GAME_ENGINES.unity) {
        companyNameRef.current.focus();
      } else {
        productNameRef.current.focus();
      }
    }
  }, [collectApplicationLogs, gameEngine]);

  useEffect(() => {
    if (!collectApplicationLogs) {
      setStreamConfigs({
        companyName: initialStreamConfigs.companyName,
        productName: initialStreamConfigs.productName,
        gameEngine: initialStreamConfigs.gameEngine,
      });
    }
  }, [collectApplicationLogs]);

  const tooltipText =
    sumOfAllUsedCapacities > 0
      ? translate("configureStream.tooltips.runningMachinePresent")
      : translate("configureStream.tooltips.featureNotAvailable");

  return (
    <div className="advanced-step">
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.autoStart.header")}
            checked={autoStart}
            disabled={advancedConfigsDisabled}
            onChange={(e) => {
              setStreamConfigs({ autoStart: e });
            }}
          />
          {advancedConfigsDisabled && (
            <HoverableTooltip content={<p>{tooltipText}</p>} side="top">
              <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.autoStart.description")}</p>
      </div>
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.restartApplication.header")}
            checked={restartApplication}
            disabled={advancedConfigsDisabled}
            onChange={(e) => {
              setStreamConfigs({ restartApplication: e });
            }}
          />
          {advancedConfigsDisabled && (
            <HoverableTooltip content={<p>{tooltipText}</p>} side="top">
              <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.restartApplication.description")}</p>
      </div>
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.collectApplicationLogs.header")}
            checked={collectApplicationLogs}
            onChange={(e) => {
              setStreamConfigs({ collectApplicationLogs: e });
            }}
          />
          {autoTurnOffDuration === 40 && collectApplicationLogs && (
            <HoverableTooltip
              content={
                <Translate
                  id="configureStream.configurations.collectApplicationLogs.tooltip"
                  options={{
                    renderInnerHtml: true,
                  }}
                />
              }
              side="right"
            >
              <Icon name="info" color="red" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.collectApplicationLogs.description")}</p>
      </div>

      {collectApplicationLogs && (
        <>
          <div className="configuration-option dropdown">
            <div>
              <p className="header">{translate("configureStream.configurations.gameEngine.header")}</p>
              <p className="description">{translate("configureStream.configurations.gameEngine.description")}</p>
            </div>
            <div className="input-box">
              <Dropdown
                underlined
                defaultSelected={gameEngine}
                options={Object.keys(GAME_ENGINES)}
                handleSelectedOptionChange={(option) => {
                  setStreamConfigs({ gameEngine: option });
                }}
                disabled={collectApplicationLogsDisabled}
                mapFunction={(option) => translate(`configureStream.configurations.gameEngine.options.${option}`)}
              />
            </div>
          </div>
          <div className="game-engine-inputs">
            {gameEngine === GAME_ENGINES.unity && (
              <div className="configuration-option dropdown">
                <div className="text-content">
                  <p className="header">{translate("configureStream.configurations.companyName.title")}</p>
                </div>
                <div className="input-box">
                  <Input
                    value={companyName}
                    onChange={(e) => setStreamConfigs({ companyName: e.target.value })}
                    inputRef={companyNameRef}
                  />
                </div>
              </div>
            )}
            {(gameEngine === GAME_ENGINES.unreal || gameEngine === GAME_ENGINES.unity) && (
              <div className="configuration-option dropdown">
                <div className="text-content">
                  <p className="header">
                    {translate(
                      `configureStream.configurations.${
                        gameEngine === GAME_ENGINES.unreal ? "projectName" : "productName"
                      }.title`,
                    )}
                  </p>
                </div>

                <div className="input-box">
                  <Input
                    value={productName}
                    onChange={(e) => setStreamConfigs({ productName: e.target.value })}
                    inputRef={productNameRef}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdvancedStep;
