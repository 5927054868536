export const GPU_STREAMING_SETTINGS = {
  quality: "standard",
  fps: 30,
};

export const CPU_STREAMING_SETTINGS = {
  quality: "standard",
  fps: 30,
};

export const STREAM_QUALITY_OPTIONS = {
  // "Very Low": "Very Low",
  Low: "low",
  Standard: "standard",
  High: "high",
  // "Very High": "Very High",
};

export const STREAM_PLAYERS = {
  WEBRTC: "webrtc",
  JMUXER: "jmuxer",
  BROADWAYJS: "broadwayjs",
};

export const STREAM_AUDIO_STATES = {
  NOT_COLLECTED: "not_collected",
  WARNING: "warning",
  MUTED: "muted",
  PLAYING: "playing",
};

export const VR_STATES = {
  disabled: "disabled",
  installing: "installing",
  running: "running",
  stopped: "stopped",
  failed: "failed",
};

export const VR_INSTALLATION_STATES = {
  "Essentials:Downloading": 0,
  "Essentials:Extracting": 5,
  "Essentials:Completed": 10,
  "SteamVR:Downloading": 15,
  "SteamVR:Extracting": 20,
  "directx:Downloading": 50,
  "directx:Extracting": 55,
  "directx:Completed": 60,
  "registry:Completed": 75,
  "config:Started": 85,
  "config:Completed": 100,
};

export const VR_BIT_RATES = {
  15: 15000000,
  25: 25000000,
  30: 30000000,
};
