import { cancelFileUpload, resetAppVersionUpload, uploadApplicationVersionFile } from "Actions/Dashboard/Files.actions";
import { FILE_ERROR, LINUX_UN_PERMITTED_EXTENSIONS, OS, UPLOAD_TYPES } from "Constants/global.constants";
import _ from "lodash";
import * as zip from "@zip.js/zip.js";
import React, { useState, useRef, useEffect, useMemo } from "react";
import CompletionImage from "Assets/images/appStreaming/configuration-completed.png";
import { useDispatch, useSelector } from "react-redux";
import { BoxInput, Dropdown, Icon, SlideModal } from "UI";
import { FileStatus, Logger, useAPI } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import {
  canUploadFiles,
  containsEmptyFile,
  containsUnAllowedFileTypes,
  firstFileInType,
  isFileSizesAllowed,
  zipsContainsFileTypes,
} from "Utils/Helpers/file.helpers";
import { useDragged } from "Utils/Hooks";
import { getApplicationsAPI } from "Actions/AppStreaming.actions";
import AppUploadArea from "../AppUploadArea/AppUploadArea.component";

import "./UploadAppVersionModal.styles.scss";

const UPLOAD_APP_VERSION_TABS = {
  upload: 0,
  executable: 1,
  finish: 2,
};

const UploadAppVersionModal = ({ setShowUploadAppVersionModal, selectedApp, translate }) => {
  const [activeTab, setActiveTab] = useState(UPLOAD_APP_VERSION_TABS.upload);
  const [appUploadError, setAppUploadError] = useState(false);
  const [versionName, setVersionName] = useState("");
  const [appExecName, setAppExecName] = useState("");
  const appUploadContainerRef = useRef(null);

  const selectedAppID = selectedApp?.id;
  const isLinux = selectedApp?.attributes?.os === OS.linux;

  const cacheCollectable = selectedApp?.attributes?.dx_cache_collectable;

  const { uploadFiles } = useSelector((state) => state.files.uploadFilesCTX) || {};
  const { uploadedFiles, lastExecutableId } = useSelector((state) => state.files.appVersionFilesCTX) || {};
  const { application_size_limit: applicationSizeLimit } =
    useSelector((state) => state.appStreaming.getVendorAccountCTX.data.attributes) || {}; // GB
  const uploadedApp = uploadedFiles?.[lastExecutableId];
  const uploadedAppContents = uploadedApp?.contents || [];
  const appExecList = useMemo(() => {
    if (isLinux) {
      return uploadedAppContents.filter((file) => {
        return !LINUX_UN_PERMITTED_EXTENSIONS.includes(file.split(".")?.pop()) && !file.endsWith("/");
      });
    }

    return uploadedAppContents.filter((file) => file.endsWith(".exe"));
  }, [uploadedAppContents, isLinux]);

  const uploadingApps = _.filter(uploadFiles, (file) => file.uploadType === UPLOAD_TYPES.APPLICATION_VERSION_UPLOAD);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(resetAppVersionUpload(lastExecutableId));
    setShowUploadAppVersionModal(false);
  };

  useEffect(() => {
    if (uploadedApp && !isLinux) setAppExecName(firstFileInType(appExecList, ".exe") || "");
  }, [uploadedApp]);

  const { isDragActive, onDragOver, onDragEnter, onDragLeave, resetDrag } = useDragged();

  const { api: updateExecutableAPI } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.CONFIGURE_EXECUTABLE(lastExecutableId),
    type: "post",
    onSuccess: () => {
      setActiveTab(UPLOAD_APP_VERSION_TABS.finish);
      dispatch(getApplicationsAPI());
    },
    onErr: () => {
      dispatch(resetAppVersionUpload(lastExecutableId));
      setActiveTab(UPLOAD_APP_VERSION_TABS.finish);
    },
  });

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;

    handleFileDrop(files);
    resetDrag();
  };

  const handleFileDrop = async (files) => {
    if (!isFileSizesAllowed(files, applicationSizeLimit * 1024)) {
      setAppUploadError(FILE_ERROR.FILE_TOO_BIG);
      return;
    }

    if (uploadingApps.length > 0) {
      setAppUploadError(FILE_ERROR.ONGOING_PROCESS);
      return;
    }

    if (Object.keys(files).length > 1) {
      setAppUploadError(FILE_ERROR.TOO_MANY_FILES);
      return;
    }

    if (canUploadFiles(files) && !containsUnAllowedFileTypes(files, ["zip"])) {
      if (!containsEmptyFile(files)) {
        const fileContents = {};
        await Promise.all(
          Object.keys(files).map(async (key) => {
            try {
              const reader = new zip.ZipReader(new zip.BlobReader(files[key]));
              let fileNames;

              await reader.getEntries().then((entries) => {
                fileNames = entries.map((entry) => entry.filename).filter((file) => !file.includes("__MACOSX"));
              });

              reader.close();
              fileContents[files[key].name] = fileNames;
            } catch (err) {
              Logger.error("AppStreamingHome handleFileDrop", err);
            }
          }),
        );
        if (isLinux ? true : zipsContainsFileTypes(fileContents, [".exe"])) {
          dispatch(uploadApplicationVersionFile({ files, fileContents, appId: selectedAppID }));
          setActiveTab(UPLOAD_APP_VERSION_TABS.executable);
        } else {
          setAppUploadError(FILE_ERROR.SHOULD_CONTAIN_EXE);
        }
      } else {
        setAppUploadError(FILE_ERROR.EMPTY_FILE_NOT_ALLOWED);
      }
    } else {
      setAppUploadError(FILE_ERROR.FOLDER_NOT_ALLOWED);
    }

    resetDrag();
  };

  const tabs = [
    {
      key: UPLOAD_APP_VERSION_TABS.upload,
      hidePrevious: true,
      overrideNextButtonText: "Close",
      overrideNextAction: onCancel,
      content: (
        <>
          <h1>{translate("appStreamingModals.uploadAppVersion.uploadStep.header")}</h1>
          <p>{translate("appStreamingModals.uploadAppVersion.uploadStep.description")}</p>
          <div className="app-upload-container" ref={appUploadContainerRef}>
            <AppUploadArea
              onFileDrop={handleFileDrop}
              error={appUploadError}
              isDragActive={isDragActive}
              translate={translate}
              acceptedFiles={[".zip"]}
            />
          </div>
        </>
      ),
    },
    {
      disabled: !uploadedApp || _.isEmpty(versionName) || _.isEmpty(appExecName),
      key: UPLOAD_APP_VERSION_TABS.executable,
      hidePrevious: true,
      onSubmit: () => {
        updateExecutableAPI({ executable_name: appExecName, version_name: versionName });
      },
      content: (
        <div className="executable-step">
          <h1>{translate("appStreamingModals.uploadAppVersion.executableStep.header")}</h1>
          <h3>{translate("appStreamingModals.uploadAppVersion.executableStep.description")}</h3>
          <div className="content">
            {uploadingApps.length > 0 && (
              <>
                {uploadingApps.map((file) => (
                  <FileStatus
                    className="file-status"
                    key={file.fileName}
                    fileName={file.fileName}
                    fileSize={file.size}
                    fileStatus={file.status}
                    fileProgress={file.progress}
                    error={file.error}
                    fileNameLimit={30}
                    onCancel={(fileName) => {
                      dispatch(cancelFileUpload(fileName));
                      setActiveTab(UPLOAD_APP_VERSION_TABS.upload);
                    }}
                    errorReason={translate("appStreamingDashboard.uploadError")}
                    translate={translate}
                    block
                  />
                ))}
              </>
            )}
            {uploadedApp && (
              <>
                <h3>{translate("appStreamingModals.uploadAppVersion.executableStep.versionName")}</h3>
                <BoxInput
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                  }}
                  color="purple-light"
                  maxLength="18"
                  placeholder="Version Name"
                />
                <h3>{translate("appStreamingModals.uploadAppVersion.executableStep.executable")}</h3>
                <Dropdown
                  className="app-folder-box"
                  defaultSelected={appExecName}
                  options={appExecList}
                  handleSelectedOptionChange={(option) => {
                    setAppExecName(option);
                  }}
                  underlined
                  disabled={appExecList.length === 0}
                />
              </>
            )}
          </div>
          <p className="footer">{translate("appStreamingModals.uploadAppVersion.footer")}</p>
        </div>
      ),
    },
    {
      key: UPLOAD_APP_VERSION_TABS.finish,
      disablePrevious: true,
      content: (
        <div className="completion-step">
          <img src={CompletionImage} />
          <h1>{translate("appStreamingModals.uploadAppVersion.finishStep.success.header")}</h1>
          <p className="completion-description">
            {translate("appStreamingModals.uploadAppVersion.finishStep.success.description")}
          </p>
          {cacheCollectable && (
            <div className="optimization-info-container">
              <Icon name="check" color="aqua-main" />
              <div className="optimization-descriptions">
                <h1>{translate("appStreamingModals.optimizationInformation.header")}</h1>
                <p>{translate("appStreamingModals.optimizationInformation.description")}</p>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <SlideModal
      className="upload-app-version-modal"
      closeAction={onCancel}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={onCancel}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={handleDrop}
      big
      translate={translate}
    />
  );
};

export default UploadAppVersionModal;
