import React, { useState, useEffect, useRef } from "react";

import { IconButton, Input } from "UI";
import { classnames } from "Utils";
import "./Dropdown.style.scss";
import _ from "lodash";

const Dropdown = (props) => {
  const {
    className,
    underlined,
    box,
    editable,
    onChange,
    mapFunction,
    defaultSelected,
    placeholder,
    options,
    handleSelectedOptionChange,
    disabled,
    overrideOnClick,
    resetOnOpen,
    keyName,
    onboarding,
    resetKey,
    ...rest
  } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState(defaultSelected || "");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (options) {
      setFilteredOptions(options);
    }
  }, [JSON.stringify(options)]);

  useEffect(() => {
    if (resetKey && filteredOptions && !filteredOptions.some((option) => _.isEqual(option, resetKey))) {
      setFilteredOptions((prev) => [resetKey, ...prev]);
    }
  }, [resetKey, filteredOptions]);

  useEffect(() => {
    if (editable && typeof inputValue === "string") {
      if (inputValue === "") {
        setFilteredOptions(options);
        return;
      }

      const filter = inputValue.toLowerCase();
      setFilteredOptions((prev) => prev.filter((option) => option.toLowerCase().includes(filter)));
    }
  }, [editable, inputValue]);

  const dropdownElement = useRef(null);

  const windowHeight = window.innerHeight;

  const posRectRef = dropdownElement.current ? dropdownElement.current.getBoundingClientRect() : {};
  const isCloseToBottom = windowHeight - 1.6 * posRectRef.y < 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownElement.current && !dropdownElement.current.contains(event.target)) {
        handleBlur();
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputValue, isOpen, handleSelectedOptionChange]); //eslint-disable-line

  useEffect(() => {
    if (selectedOption && editable) {
      if (mapFunction) {
        setInputValue(mapFunction(selectedOption));
      } else {
        setInputValue(selectedOption);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      defaultSelected === null &&
      resetKey &&
      selectedOption &&
      _.isEqual(mapFunction ? mapFunction(resetKey) : resetKey, selectedOption)
    ) {
      return;
    }

    if (defaultSelected) {
      setSelectedOption(mapFunction ? mapFunction(defaultSelected) : defaultSelected);
    } else {
      setSelectedOption(null);
    }
  }, [defaultSelected]);

  // Force user to select from list
  const handleBlur = () => {
    if (!inputValue || !inputValue.toLowerCase) {
      return;
    }

    const filter = inputValue.toLowerCase();
    const closestOptions = options.filter((option) => option?.toLowerCase().includes(filter));
    let closestOption = "";
    if (closestOptions.length > 0) {
      [closestOption] = closestOptions;
    }
    setInputValue(closestOption);
    handleSelectedOptionChange(closestOption);
  };

  const handleEnterKeyUp = (event, option) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleSelectedOptionChange(option);
      setSelectedOption(option);
      setIsOpen(false);
    }
  };

  const dropdownClassName = classnames([
    "vg-dropdown",
    className,
    disabled && "disabled",
    underlined && "underlined",
    box && "box",
    isCloseToBottom && "reverse",
    onboarding && "onboarding",
  ]);

  useEffect(() => {
    if (resetOnOpen && isOpen && options && options.includes(inputValue)) {
      setInputValue("");
    }
  }, [isOpen]);

  return (
    <div className={dropdownClassName} ref={dropdownElement}>
      <div
        className={selectedOption ? "selected" : "placeholder"}
        onClick={() => {
          if (overrideOnClick) {
            overrideOnClick();
            return;
          }
          setIsOpen(!disabled && (editable || !isOpen));
        }}
        onKeyUp={() => {
          if (overrideOnClick) {
            return;
          }
          setIsOpen(!disabled && (editable || !isOpen));
        }}
        role="button"
        tabIndex={0}
      >
        {editable ? (
          <Input
            type="text"
            placeholder={placeholder}
            inputValue={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
              onChange(event);
              if (!isOpen) {
                setIsOpen(true);
              }
            }}
            {...rest}
          />
        ) : (
          <span className={selectedOption || onboarding ? "selected" : "placeholder"}>
            {selectedOption || placeholder}
          </span>
        )}
        <IconButton
          name={isOpen ? "chevron-up" : "chevron-down"}
          onClick={(event) => {
            event.stopPropagation();
            if (overrideOnClick) {
              overrideOnClick();
              return;
            }
            if (!disabled) {
              setIsOpen(!isOpen);
            }
          }}
          disabled={disabled}
        />
      </div>
      {isOpen && filteredOptions?.length > 0 && (
        <div className={`content ${editable ? "editable" : ""} `}>
          {filteredOptions.map((option) => {
            if (mapFunction && !mapFunction(option)) return null;
            const optionText = mapFunction ? mapFunction(option) : option;
            return (
              <div
                key={option?.id || option[keyName] || option}
                className={classnames(["dropdown-item", option.disabled && "disabled"])}
                onClick={() => {
                  if (option.disabled) return;
                  if (resetKey && _.isEqual(option, resetKey)) {
                    setSelectedOption(mapFunction ? mapFunction(option) : option);
                    handleSelectedOptionChange(null);
                    setIsOpen(false);
                    return;
                  }
                  handleSelectedOptionChange(option);
                  setSelectedOption(mapFunction ? mapFunction(option) : option);
                  setIsOpen(false);
                }}
                role="button"
                tabIndex={0}
                onKeyUp={(event) => handleEnterKeyUp(event, option)}
              >
                {optionText}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
